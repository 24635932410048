import { create } from 'zustand'

interface DataFilterTranslados {
  airport: string
  updateAirport: (airport: string) => void
}
export const dataFilterTransladosStore = create<DataFilterTranslados>()(
  (set) => ({
    airport: '',
    updateAirport: (airport) => set(() => ({ airport }))
  })
)
