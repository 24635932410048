import { Tab } from '@headlessui/react'

import React, { Fragment, useMemo, useState } from 'react'
import CommonLayout from './CommonLayout'
import { useCompra } from 'service/useCompra'
import CardAirpot from 'components/CardAirpot'
import { v4 } from 'uuid'
import { Tour, Traslado } from 'generated/graphql'
import Heading from 'shared/Heading/Heading'
import ToursCard from 'components/ToursCard'
import { RenderLoading, Show } from 'shared/Show'
import { Helmet } from 'react-helmet'

const AccountSavelists = () => {
  let [categories] = useState(['Traslados', 'Tours'])

  const { Compras } = useCompra()
  const { dataCompras, loadingCompras } = Compras()
  const dataTraslado = useMemo(
    () =>
      dataCompras.filter((item) => item.attributes?.traslado?.data !== null),
    [loadingCompras]
  )
  const dataTours = useMemo(
    () => dataCompras.filter((item) => item.attributes?.tour?.data !== null),
    [loadingCompras]
  )

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Historial de compras</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? 'bg-secondary-900 text-secondary-50 '
                          : 'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  <Show
                    condition={!loadingCompras}
                    isDefault={<RenderLoading />}
                  >
                    {dataTraslado.length !== 0 ? (
                      <>
                        {dataTraslado &&
                          dataTraslado?.map((traslado) => (
                            <CardAirpot
                              key={v4()}
                              showBuy={false}
                              data={
                                traslado.attributes?.traslado?.data
                                  ?.attributes as Traslado
                              }
                              id={traslado.attributes?.traslado?.data?.id!}
                            />
                          ))}
                      </>
                    ) : (
                      <Heading>No hay Traslados</Heading>
                    )}
                  </Show>
                </div>
              </Tab.Panel>
              <Tab.Panel className="mt-28">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  <Show
                    condition={!loadingCompras}
                    isDefault={<RenderLoading />}
                  >
                    {dataTours.length !== 0 ? (
                      <>
                        {dataTours &&
                          dataTours?.map((tour) => (
                            <ToursCard
                              key={v4()}
                              data={
                                tour.attributes?.tour?.data?.attributes as Tour
                              }
                              id={tour.attributes?.tour?.data?.id}
                            />
                          ))}
                      </>
                    ) : (
                      <Heading>No hay Tours</Heading>
                    )}
                  </Show>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Helmet>
        <title>Travia | Historial de compras</title>
      </Helmet>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  )
}

export default AccountSavelists
