import React, { Dispatch, useState } from 'react'
import { GuestsObject } from 'components/HeroSearchForm/type'
import moment from 'moment'
import { MapPinIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import DatesRangeInputOne from 'components/HeroSearchForm2Mobile/DatesRangeInputOne'
import GuestsInput from 'components/HeroSearchForm2Mobile/GuestsInput'
import { useCities } from 'service/useCities'

interface Props {
  setGuestAdultsInputValue: Dispatch<React.SetStateAction<number>>
  setGuestChildrenInputValue: Dispatch<React.SetStateAction<number>>
  setGuestInfantsInputValue: Dispatch<React.SetStateAction<number>>
  guestInfantsInputValue: number
  guestAdultsInputValue: number
  guestChildrenInputValue: number
  value: string
  setValue: Dispatch<React.SetStateAction<string>>
}

const StaySearchFormCustom = ({
  setGuestInfantsInputValue,
  setGuestAdultsInputValue,
  setGuestChildrenInputValue,
  guestInfantsInputValue,
  guestAdultsInputValue,
  guestChildrenInputValue,
  value,
  setValue
}: Props) => {
  //
  const [fieldNameShow, setFieldNameShow] = useState<
    'location' | 'dates' | 'guests'
  >('location')
  //

  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0
  })
  const [startDate, setStartDate] = useState<Date | null>(new Date())

  const { CitiesFilter } = useCities()
  const { dataCitiesFilter } = CitiesFilter({ nombre: value })

  //
  const renderSearchValues = ({ heading }: { heading: string }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || 'Destinations'}
        </p>
        <div className="mt-3">
          {dataCitiesFilter?.slice(0, 5).map((item) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer"
                onClick={() => setValue(item.attributes?.nombre!)}
                key={item.id}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{item.attributes?.nombre!}</span>
              </div>
            )
          })}
        </div>
      </>
    )
  }
  const renderInputLocation = () => {
    const isActive = fieldNameShow === 'location'
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('location')}
          >
            <span className="text-neutral-400">Donde</span>
          </button>
        ) : (
          <div>
            <div className="p-5">
              <span className="block font-semibold text-xl sm:text-2xl">
                ¿A donde?
              </span>

              <div className="relative mt-5">
                <input
                  className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
                  placeholder={'Buscar ciudades'}
                  value={value}
                  onChange={(e) => {
                    setValue(e.currentTarget.value)
                  }}
                />
                <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
                  <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
                </span>
              </div>
              <div className="mt-7">
                {renderSearchValues({
                  heading: 'Ciudades populares'
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderInputDates = () => {
    const isActive = fieldNameShow === 'dates'

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow('dates')}
          >
            <span className="text-neutral-400">Cuando</span>

            <span>
              {startDate ? `${moment(startDate).format('MMM DD')}` : 'Add date'}
            </span>
          </button>
        ) : (
          <DatesRangeInputOne
            startDate={startDate}
            setStartDate={setStartDate}
          />
        )}
      </div>
    )
  }

  const renderInputGuests = () => {
    const isActive = fieldNameShow === 'guests'
    let guestSelected = ''
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const guest =
        (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0)
      guestSelected += `${guest} guests`
    }

    if (guestInput.guestInfants) {
      guestSelected += `, ${guestInput.guestInfants} infants`
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('guests')}
          >
            <span className="text-neutral-400">Quien</span>
            <span>{guestSelected || `Agregar persona`}</span>
          </button>
        ) : (
          <GuestsInput
            setGuestInfantsInputValue={setGuestInfantsInputValue}
            setGuestChildrenInputValue={setGuestChildrenInputValue}
            setGuestAdultsInputValue={setGuestAdultsInputValue}
            defaultValue={guestInput}
            onChange={setGuestInput}
            guestInfantsInputValue={guestInfantsInputValue}
            guestChildrenInputValue={guestChildrenInputValue}
            guestAdultsInputValue={guestAdultsInputValue}
          />
        )}
      </div>
    )
  }

  return (
    <div>
      <div className="w-full space-y-5">
        {/*  */}
        {renderInputLocation()}
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {renderInputGuests()}
      </div>
    </div>
  )
}

export default StaySearchFormCustom
