import Glide from '@glidejs/glide'
import Heading from 'components/Heading/Heading'
import React, { FC } from 'react'
import { useEffect } from 'react'
import clientSayMain from 'images/clientSayMain.png'
import clientSay1 from 'images/clientSay1.png'
import clientSay2 from 'images/clientSay2.png'
import clientSay3 from 'images/clientSay3.png'
import clientSay4 from 'images/clientSay4.png'
import clientSay5 from 'images/clientSay5.png'
import clientSay6 from 'images/clientSay6.png'
import quotationImg from 'images/quotation.png'
import quotationImg2 from 'images/quotation2.png'
import useNcId from 'hooks/useNcId'
import { useDataCmsStore } from 'store/dataCms'

export interface SectionClientSayProps {
  className?: string
  uniqueClassName: string
}

const DEMO_DATA = [
  {
    id: 1,
    clientName: 'María',
    clientAddress: 'Cartagena',
    content:
      '¡Una experiencia inolvidable! No puedo expresar con palabras lo maravilloso que fue nuestro viaje con Travia. Cada detalle fue cuidadosamente planificado, desde los alojamientos hasta las actividades. ¡Gracias por hacer de nuestro sueño una realidad!'
  },
  {
    id: 2,
    clientName: 'Juan',
    clientAddress: 'Cali',
    content:
      'El mejor servicio que he tenido. Quiero agradecer a todo el equipo de Travia por su profesionalidad y amabilidad durante nuestro viaje. Cada momento estuvo lleno de sorpresas agradables y atención personalizada. ¡Definitivamente volveré a viajar con ustedes!'
  },
  {
    id: 3,
    clientName: 'Laura',
    clientAddress: 'Ibague',
    content:
      'Recomendaría Travia a todos. Mi experiencia con Travia superó todas mis expectativas. Desde el primer contacto, me sentí respaldada y cuidada. Los destinos elegidos fueron espectaculares y los guías turísticos fueron excepcionales. ¡No dudaría en recomendar Travia a mis amigos y familiares!'
  }
]

const SectionClientSay: FC<SectionClientSayProps> = ({
  className = '',
  uniqueClassName = ''
}) => {
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}` + useNcId()

  const dataCms = useDataCmsStore((state) => state.dataCms)

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      setTimeout(() => {
        new Glide(`.${UNIQUE_CLASS}`, {
          perView: 1
        }).mount()
      }, 10)
    }
  }, [])

  const renderBg = () => {
    return (
      <div className="hidden md:block">
        <img className="absolute top-9 -left-20" src={clientSay1} alt="" />
        <img
          className="absolute bottom-[100px] right-full mr-40"
          src={clientSay2}
          alt=""
        />
        <img
          className="absolute top-full left-[140px]"
          src={clientSay3}
          alt=""
        />
        <img
          className="absolute -bottom-10 right-[140px]"
          src={clientSay4}
          alt=""
        />
        <img
          className="absolute left-full ml-32 bottom-[80px]"
          src={clientSay5}
          alt=""
        />
        <img className="absolute -right-10 top-10 " src={clientSay6} alt="" />
      </div>
    )
  }

  return (
    <div
      className={`nc-SectionClientSay relative ${className} `}
      data-nc-id="SectionClientSay"
    >
      <Heading desc={dataCms.descripcionTestimonios} isCenter>
        {dataCms.tituloTestimonios}
      </Heading>
      <div className="relative md:mb-16 max-w-2xl mx-auto">
        {renderBg()}
        <img className="mx-auto" src={clientSayMain} alt="" />
        <div className={`mt-12 lg:mt-16 relative ${UNIQUE_CLASS}`}>
          <img
            className="opacity-50 md:opacity-100 absolute -mr-16 lg:mr-3 right-full top-1"
            src={quotationImg}
            alt=""
          />
          <img
            className="opacity-50 md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-1"
            src={quotationImg2}
            alt=""
          />
          <div className="glide__track " data-glide-el="track">
            <ul className="glide__slides ">
              {DEMO_DATA.map((item) => (
                <li
                  key={item.id}
                  className="glide__slide flex flex-col items-center text-center"
                >
                  <span className="block text-2xl">{item.content}</span>
                  <span className="block mt-8 text-2xl font-semibold">
                    {item.clientName}
                  </span>
                  <div className="flex items-center space-x-2 text-lg mt-2 text-neutral-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <span>{item.clientAddress}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div
            className="mt-10 glide__bullets flex items-center justify-center"
            data-glide-el="controls[nav]"
          >
            {DEMO_DATA.map((item, index) => (
              <button
                key={item.id}
                className="glide__bullet w-2 h-2 rounded-full bg-neutral-300 mx-1 focus:outline-none"
                data-glide-dir={`=${index}`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionClientSay
