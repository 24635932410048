import React, { FC, useState } from 'react'
// import facebookSvg from 'images/Facebook.svg'
// import twitterSvg from 'images/Twitter.svg'
// import googleSvg from 'images/Google.svg'
import { Helmet } from 'react-helmet'
import Input from 'shared/Input/Input'
import { Link, useNavigate } from 'react-router-dom'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import { useAuth } from 'service/useAuth'
import { useSessionStore } from 'store/session'
import { Toast } from 'utils/toast'
import Cookies from 'js-cookie'

export interface PageLoginProps {
  className?: string
}

// const loginSocials = [
//   {
//     name: 'Continue with Facebook',
//     href: '#',
//     icon: facebookSvg
//   },
//   {
//     name: 'Continue with Twitter',
//     href: '#',
//     icon: twitterSvg
//   },
//   {
//     name: 'Continue with Google',
//     href: '#',
//     icon: googleSvg
//   }
// ]

const PageLogin: FC<PageLoginProps> = ({ className = '' }) => {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const authUpdate = useSessionStore((state) => state.authUpdate)
  const userUpdate = useSessionStore((state) => state.userUpdate)

  const { Login, loading } = useAuth()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (userName.length === 0 || password.length === 0) {
      Toast({ type: 'error', message: 'Debes llenar todos los campos' })
      return
    }
    try {
      const res = await Login({
        identifier: userName,
        password: password,
        provider: 'local'
      })
      Cookies.set('token', res.data?.login.jwt!)
      Cookies.set('user', JSON.stringify(res.data?.login.user!))
      userUpdate(res.data?.login.user!)
      authUpdate(true)
      navigate('/')
    } catch (error) {
      Toast({ type: 'error', message: 'Error desconocido' })
    }
  }
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Ingresar || Travia</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Ingresar
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="grid grid-cols-1 gap-6"
            action="#"
            method="post"
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Username
              </span>
              <Input
                type="text"
                value={userName}
                placeholder="correo@correo.com"
                className="mt-1"
                onChange={(e) => {
                  setUserName(e.target.value)
                }}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Contraseña
              </span>
              <Input
                type="password"
                className="mt-1"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
            </label>
            <ButtonPrimary loading={loading} type="submit">
              Continuar
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            ¿Nuevo usuario? {` `}
            <Link to="/signup">Crear cuenta</Link>
          </span>
        </div>
      </div>
    </div>
  )
}

export default PageLogin
