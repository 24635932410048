import {
  InputMaybe,
  TourEntity,
  TourFiltersInput,
  useTourQuery,
  useToursQuery
} from 'generated/graphql'

export const useTours = () => {
  const Tours = ({ filters }: { filters: TourFiltersInput }) => {
    const {
      data,
      loading: loadingTours,
      error: errorTours
    } = useToursQuery({
      fetchPolicy: 'network-only',
      variables: {
        filters
      }
    })
    const dataTours = data?.tours?.data ?? []
    const dataPagination = data?.tours?.meta.pagination.total ?? 0 ?? []
    return {
      dataTours,
      loadingTours,
      errorTours,
      dataPagination
    }
  }
  const Tour = ({ tourId }: { tourId: InputMaybe<string> | undefined }) => {
    const {
      data,
      loading: loadingTour,
      error: errorTour
    } = useTourQuery({
      fetchPolicy: 'network-only',
      variables: {
        tourId
      }
    })
    const dataTour = data?.tour?.data ?? ([] as TourEntity)
    return {
      dataTour,
      loadingTour,
      errorTour
    }
  }

  return {
    Tours,
    Tour
  }
}
