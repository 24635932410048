import { GuestsObject } from 'components/HeroSearchForm/type'
import NcInputNumber from 'components/NcInputNumber/NcInputNumber'
import React, { Dispatch, useEffect } from 'react'
import { FC } from 'react'
export interface GuestsInputProps {
  defaultValue?: GuestsObject
  onChange?: (data: GuestsObject) => void
  className?: string
  setGuestAdultsInputValue?: Dispatch<React.SetStateAction<number>>
  setGuestChildrenInputValue?: Dispatch<React.SetStateAction<number>>
  setGuestInfantsInputValue?: Dispatch<React.SetStateAction<number>>
  guestInfantsInputValue?: number
  guestAdultsInputValue?: number
  guestChildrenInputValue?: number
}

const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  className = '',
  setGuestInfantsInputValue,
  setGuestAdultsInputValue,
  setGuestChildrenInputValue,
  guestInfantsInputValue,
  guestAdultsInputValue,
  guestChildrenInputValue
}) => {
  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue
    }
    if (type === 'guestAdults') {
      setGuestAdultsInputValue!(value)
      newValue.guestAdults = value
    }
    if (type === 'guestChildren') {
      setGuestChildrenInputValue!(value)
      newValue.guestChildren = value
    }
    if (type === 'guestInfants') {
      setGuestInfantsInputValue!(value)
      newValue.guestInfants = value
    }
    onChange && onChange(newValue)
  }

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {`¿Cuantos van?`}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={guestAdultsInputValue}
        onChange={(value) => handleChangeData(value, 'guestAdults')}
        max={20}
        label="Adultos"
        desc="13 años o más"
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestChildrenInputValue}
        onChange={(value) => handleChangeData(value, 'guestChildren')}
        max={20}
        label="Niños"
        desc="2 - 12 años"
      />

      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestInfantsInputValue}
        onChange={(value) => handleChangeData(value, 'guestInfants')}
        max={20}
        label="Bebes"
        desc="Menores de 2 años"
      />
    </div>
  )
}

export default GuestsInput
