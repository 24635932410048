import rightImg from 'images/about-hero-right.png'
import React, { FC } from 'react'

import { Helmet } from 'react-helmet'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import SectionHero from './SectionHero'
import SectionClientSay from 'components/SectionClientSay/SectionClientSay'

export interface PageAboutProps {
  className?: string
}

const PageAbout: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Travia | Contacto</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="Conoce a Travia: Nuestra Pasión por los Viajes ✈️🌍"
          btnText=""
          subHeading="En Travia, estamos impulsados por nuestra pasión inquebrantable por los viajes. Somos viajeros entusiastas que creemos en la magia de explorar nuevos destinos y sumergirse en diferentes culturas. Nuestro objetivo es convertir tus sueños de viaje en realidades inolvidables. Desde traslados perfectamente organizados hasta tours emocionantes, nos esforzamos por brindarte experiencias excepcionales y momentos que atesorarás para siempre. Únete a nosotros en esta increíble aventura mientras juntos descubrimos el mundo y creamos recuerdos inolvidables. ¡Bienvenido a Travia, donde los sueños de viaje se hacen realidad! ✨✈️🌍"
        />

        {/* <SectionFounder /> */}
        <SectionSubscribe2 />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        {/* <SectionStatistic /> */}
      </div>
    </div>
  )
}

export default PageAbout
