/* eslint-disable react-hooks/rules-of-hooks */
import {} from '@heroicons/react/24/outline'
import React, { FC, useMemo } from 'react'

import NcModal from 'shared/NcModal/NcModal'
import ModalSelectDate from 'components/ModalSelectDate'
import ModalSelectGuests from 'components/ModalSelectGuests'

import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import { useParams } from 'react-router-dom'
import { DataBuyStore } from 'store/dataBuy'
import moment from 'moment'
import { useTours } from 'service/useTours'
import { useTranslados } from 'service/useTranslados'
export interface CheckOutPagePageMainProps {
  className?: string
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = ''
}) => {
  const { id } = useParams()
  const dataBuyPerson = DataBuyStore((state) => state.dataBuyPerson)
  const { Traslado } = useTranslados()
  const { Tour } = useTours()

  const { dataTour } = Tour({ tourId: dataBuyPerson?.tour })
  const { dataTraslado } = Traslado({ trasladoId: dataBuyPerson?.traslados })

  initMercadoPago('TEST-c909ebad-3c08-448f-8a4d-4305f3d83bd8')

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={
                  dataBuyPerson.plan === 'traslado'
                    ? dataTraslado.attributes?.vehiculo?.data?.attributes
                        ?.imagenReferencia?.data?.attributes?.url
                    : dataTour.attributes?.ImagenDestacada.data?.attributes?.url
                }
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {dataBuyPerson.plan === 'traslado'
                  ? `${dataTraslado.attributes?.zonadeArribos} `
                  : `${dataTour.attributes?.ciudad} - ${dataTour.attributes?.categoria_tours?.data[0].attributes?.nombre} `}
              </span>
              <span className="text-base font-medium mt-1 block">
                {dataBuyPerson.plan === 'traslado'
                  ? `${dataTraslado.attributes?.TipodeServicio} - 
                  ${dataTraslado.attributes?.vehiculo?.data?.attributes?.tipodeVehiculo}`
                  : `${dataTour.attributes?.Nombre} `}
              </span>
            </div>

            {/* <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div> */}
            {/* <StartRating /> */}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Detalle del precio</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{dataBuyPerson?.cantidadDePersonas} Personas</span>
            <span>${dataBuyPerson.total}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Cargo por servicio</span>
            <span>$0</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>${dataBuyPerson.total}</span>
          </div>
        </div>
      </div>
    )
  }
  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">Confirmar y pago</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Tu viaje</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  Ver detalles de compra
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Detalle de la compra"
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDate
              renderChildren={({ openModal }) => (
                <button
                  // onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Fecha</span>
                    <span className="mt-1.5 text-lg font-semibold capitalize">
                      {moment(dataBuyPerson.fechadelServicio).format('MMM DD')}
                      {/* {converSelectedDateToString([startDate, endDate])} */}
                    </span>
                  </div>
                  {/* <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" /> */}
                </button>
              )}
            />

            <ModalSelectGuests
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  // onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Personas</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${
                          (dataBuyPerson.guestAdults || 0) +
                          (dataBuyPerson.guestChildren || 0)
                        } Personas, ${dataBuyPerson.guestInfants || 0} ${
                          dataBuyPerson.guestInfants === 1 ? 'Bebe' : 'Bebes'
                        }`}
                      </span>
                    </span>
                  </div>
                  {/* <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" /> */}
                </button>
              )}
            />
          </div>
        </div>

        <div>
          <div className="mt-2">
            {useMemo(
              () => (
                <Wallet
                  initialization={{
                    preferenceId: id!,
                    redirectMode: 'modal'
                  }}
                />
              ),
              []
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  )
}

export default CheckOutPagePageMain
