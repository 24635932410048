import Label from 'components/Label/Label'
import React, { useState } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Input from 'shared/Input/Input'
import CommonLayout from './CommonLayout'
import { useAuth } from 'service/useAuth'
import { Toast } from 'utils/toast'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const AccountPass = () => {
  const [password, setPassword] = useState('')
  const [passwordNew, setPasswordNew] = useState('')
  const [passwordNewConfirm, setPasswordNewConfirm] = useState('')

  const { ChangePassword, loadingChange } = useAuth()

  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (
      password.length === 0 ||
      passwordNew.length === 0 ||
      passwordNewConfirm.length === 0
    ) {
      Toast({ type: 'error', message: 'Debes llenar todos los campos' })
      return
    }
    try {
      await ChangePassword({
        currentPassword: password,
        password: passwordNew,
        passwordConfirmation: passwordNewConfirm
      })

      Toast({
        type: 'success',
        message: 'Se actualizo la contraseña correctamente'
      })
      navigate('/')
    } catch (error: any) {
      Toast({ type: 'error', message: 'Error desconocido' })
    }
  }
  <Helmet>
        <title>Travia | Cambiar contraseña</title>
      </Helmet>
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Cambiar contraseña</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <form className=" max-w-xl space-y-6" onSubmit={handleSubmit}>
            <div>
              <Label>Contraseña</Label>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="mt-1.5"
              />
            </div>
            <div>
              <Label>Nueva contraseña</Label>
              <Input
                value={passwordNew}
                onChange={(e) => setPasswordNew(e.target.value)}
                type="password"
                className="mt-1.5"
              />
            </div>
            <div>
              <Label>Confirmar contraseña</Label>
              <Input
                value={passwordNewConfirm}
                onChange={(e) => setPasswordNewConfirm(e.target.value)}
                type="password"
                className="mt-1.5"
              />
            </div>
            <div className="pt-2">
              <ButtonPrimary loading={loadingChange} type="submit">
                Cambiar contraseña
              </ButtonPrimary>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  )
}

export default AccountPass
