import React, { Fragment, FC, useState, Dispatch, SetStateAction } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { UserPlusIcon } from '@heroicons/react/24/outline'
import { GuestsObject } from 'components/HeroSearchForm/type'
import ClearDataButton from 'components/HeroSearchForm/ClearDataButton'
import NcInputNumber from 'components/NcInputNumber/NcInputNumber'
import ButtonSubmit from 'components/Buttons/ButtonSubmit'

export interface GuestsInputComponenteProps {
  className?: string
  setGuestChildrenInputValue: Dispatch<SetStateAction<number | undefined>>
  setGuestAdultsInputValue: Dispatch<SetStateAction<number | undefined>>
  setGuestInfantsInputValue: Dispatch<SetStateAction<number | undefined>>
  guestInfantsInputValue: number
  guestAdultsInputValue: number
  guestChildrenInputValue: number
  hasButtonSubmit?: boolean
  handleClick?: () => void
}

const GuestsInputComponente: FC<GuestsInputComponenteProps> = ({
  className = 'flex-1',
  setGuestChildrenInputValue,
  setGuestAdultsInputValue,
  setGuestInfantsInputValue,
  guestInfantsInputValue,
  guestAdultsInputValue,
  guestChildrenInputValue,
  handleClick,
  hasButtonSubmit
}) => {
  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue
    }
    if (type === 'guestAdults') {
      setGuestAdultsInputValue(value)
      newValue.guestAdults = value
    }
    if (type === 'guestChildren') {
      setGuestChildrenInputValue(value)
      newValue.guestChildren = value
    }
    if (type === 'guestInfants') {
      setGuestInfantsInputValue(value)
      newValue.guestInfants = value
    }
  }

  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none rounded-b-3xl ${
              open ? 'shadow-lg' : ''
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center p-3 space-x-3 focus:outline-none`}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ''} Personas
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? 'Personas' : 'Agregar personas'}
                </span>
              </div>

              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setGuestAdultsInputValue(0)
                    setGuestChildrenInputValue(0)
                    setGuestInfantsInputValue(0)
                  }}
                />
              )}
            </Popover.Button>
            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4 cursor-pointer">
                <ButtonSubmit handleClick={handleClick} />
              </div>
            )}
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black ring-opacity-5 ">
              <NcInputNumber
                className="w-full"
                defaultValue={guestAdultsInputValue}
                onChange={(value) => {
                  handleChangeData(value, 'guestAdults')
                  setGuestAdultsInputValue(value)
                }}
                max={10}
                min={1}
                label="Adults"
                desc="Ages 13 or above"
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestChildrenInputValue}
                onChange={(value) => {
                  handleChangeData(value, 'guestChildren')

                  setGuestChildrenInputValue(value)
                }}
                max={4}
                label="Children"
                desc="Ages 2–12"
              />

              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestInfantsInputValue}
                onChange={(value) => {
                  handleChangeData(value, 'guestInfants')

                  setGuestInfantsInputValue(value)
                }}
                max={4}
                label="Infants"
                desc="Ages 0–2"
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default GuestsInputComponente
