'use client'

import { MapPinIcon } from '@heroicons/react/24/outline'
import React, {
  useState,
  useRef,
  useEffect,
  FC,
  FormEvent,
  RefObject,
  LegacyRef
} from 'react'
import Autocomplete from 'react-google-autocomplete'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import ClearDataButton from 'components/HeroSearchForm/ClearDataButton'

export interface LocationGoogleProps {
  placeHolder?: string
  desc?: string
  className?: string
  divHideVerticalLineClass?: string
  autoFocus?: boolean
  value: string | null
  handleChange: (value: any) => void
}

const LocationGoogle: FC<LocationGoogleProps> = ({
  autoFocus = false,
  placeHolder = 'Location',
  desc = 'Where are you going?',
  className = 'nc-flex-1.5',
  divHideVerticalLineClass = 'left-10 -right-0.5',
  value,
  handleChange
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const googleInputGoogleRef: React.RefObject<HTMLInputElement | undefined> =
    useRef<HTMLInputElement>()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  const [showPopover, setShowPopover] = useState(autoFocus)

  useEffect(() => {
    if (queryParams.dataCiudad) {
      googleInputGoogleRef.current!.value = queryParams?.dataCiudad! as string
    }
  }, [])

  useEffect(() => {
    setShowPopover(autoFocus)
  }, [autoFocus])

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener('click', eventClickOutsideDiv)
    }
    showPopover && document.addEventListener('click', eventClickOutsideDiv)
    return () => {
      document.removeEventListener('click', eventClickOutsideDiv)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover])

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus()
    }
  }, [showPopover])

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return
    }
    // CLICK OUT_SIDE
    setShowPopover(false)
  }

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? 'nc-hero-field-focused' : ''
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <Autocomplete
            className=" dark:text-white   block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none dark:focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
            ref={
              googleInputGoogleRef as
                | (RefObject<HTMLInputElement | undefined> &
                    LegacyRef<HTMLInputElement>)
                | undefined
            }
            apiKey="AIzaSyDu5MNkinB4TmnyRixzuu9SIbUeZY-dPGo"
            onPlaceSelected={(place) => {
              handleChange(place.formatted_address)
            }}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              handleChange((e.target as any).value)
            }
          />

          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
          {value && showPopover && <ClearDataButton onClick={() => {}} />}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {/* {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {isValueThree ? renderRecentSearches() : renderSearchValue()}
        </div>
      )} */}
    </div>
  )
}

export default LocationGoogle
