import {
  UsersPermissionsLoginInput,
  UsersPermissionsRegisterInput,
  UsersPermissionsUserInput,
  useChangePasswordMutation,
  useLoginMutation,
  useRegisterMutation,
  useUpdateUsersPermissionsUserMutation
} from 'generated/graphql'

export const useAuth = () => {
  const [loginMutation, { loading, error }] = useLoginMutation()
  const [registerMutation, { loading: loadingRegister, error: errorRegister }] =
    useRegisterMutation()
  const [
    changePasswordMutation,
    { loading: loadingChange, error: errorChange }
  ] = useChangePasswordMutation()
  const [
    updateUsersPermissionsUserMutation,
    { loading: loadingUpdateUsers, error: errorUpdateUsers }
  ] = useUpdateUsersPermissionsUserMutation()

  return {
    loading,
    error,
    loadingRegister,
    errorRegister,
    loadingUpdateUsers,
    errorUpdateUsers,
    errorChange,
    loadingChange,
    Login: async (input: UsersPermissionsLoginInput) => {
      const res = await loginMutation({
        variables: { input }
      })

      const data = res.data

      return {
        data
      }
    },
    Register: async (input: UsersPermissionsRegisterInput) => {
      const res = await registerMutation({
        variables: { input }
      })
      const data = res.data
      return {
        data
      }
    },
    UpdateUser: async ({
      id,
      data
    }: {
      id: string
      data: UsersPermissionsUserInput
    }) => {
      const res = await updateUsersPermissionsUserMutation({
        variables: { updateUsersPermissionsUserId: id, data }
      })
      const dataUser = res.data
      return {
        dataUser
      }
    },
    ChangePassword: async ({
      currentPassword,
      password,
      passwordConfirmation
    }: {
      currentPassword:string
      password:string
      passwordConfirmation:string
    }) => {
      const res = await changePasswordMutation({
        variables: { currentPassword, password, passwordConfirmation }
      })
      const dataPassword = res.data?.changePassword ?? {}
      return {
        dataPassword
      }
    }
  }
}
