import { create } from 'zustand'

interface DataCms {
  tituloHeader: string
  subTituloHeader: string
  textoBotonHeader: string
  img1Header: string
  // img2Header: string
  img3Header: string
  tituloCiudades: string
  subTituloCiudades: string
  sloganBeneficios: string
  tituloBeneficios: string
  tag1Beneficios: string
  tag2Beneficios: string
  tag3Beneficios: string
  itemTitulo1Beneficios: string
  itemTitulo2Beneficios: string
  itemTitulo3Beneficios: string
  itemDescripcion1Beneficios: string
  itemDescripcion2Beneficios: string
  itemDescripcion3Beneficios: string
  tituloTours: string
  descripcionTours: string
  tituloComoFunciona: string
  descripcionComoFunciona: string
  item1TituloComoFunciona: string
  item2TituloComoFunciona: string
  item3TituloComoFunciona: string
  item1DescripcionComoFunciona: string
  item2DescripcionComoFunciona: string
  item3DescripcionComoFunciona: string
  tituloBoletin: string
  descripcionBoletin: string
  item1Boletin: string
  item2Boletin: string
  tituloVideo: string
  DescripcionVideo: string
  tituloTestimonios: string
  descripcionTestimonios: string
  logoClaro: string
  logoOscuro: string
}

interface DataCmsState {
  dataCms: DataCms
  dataCmsUpdate: (value: DataCms) => void
}
export const useDataCmsStore = create<DataCmsState>()((set) => ({
  dataCms: {
    tituloHeader: '',
    subTituloHeader: '',
    textoBotonHeader: '',
    img1Header: '',
    // img2Header: '',
    img3Header: '',
    tituloCiudades: '',
    subTituloCiudades: '',
    sloganBeneficios: '',
    tituloBeneficios: '',
    tag1Beneficios: '',
    tag2Beneficios: '',
    tag3Beneficios: '',
    itemTitulo1Beneficios: '',
    itemTitulo2Beneficios: '',
    itemTitulo3Beneficios: '',
    itemDescripcion1Beneficios: '',
    itemDescripcion2Beneficios: '',
    itemDescripcion3Beneficios: '',
    tituloTours: '',
    descripcionTours: '',
    tituloComoFunciona: '',
    descripcionComoFunciona: '',
    item1TituloComoFunciona: '',
    item2TituloComoFunciona: '',
    item3TituloComoFunciona: '',
    item1DescripcionComoFunciona: '',
    item2DescripcionComoFunciona: '',
    item3DescripcionComoFunciona: '',
    tituloBoletin: '',
    descripcionBoletin: '',
    item1Boletin: '',
    item2Boletin: '',
    tituloVideo: '',
    DescripcionVideo: '',
    tituloTestimonios: '',
    descripcionTestimonios: '',
    logoClaro: '',
    logoOscuro: ''
  },
  dataCmsUpdate: (value) => set(() => ({ dataCms: value }))
}))
