import {
  InputMaybe,
  PaginationArg,
  TrasladoEntity,
  TrasladoFiltersInput,
  useTrasladoQuery,
  useTrasladosQuery
} from 'generated/graphql'

export const useTranslados = () => {
  const Translados = ({
    filters,
    pagination
  }: {
    filters: TrasladoFiltersInput
    pagination?: PaginationArg
  }) => {
    const { data, loading, error } = useTrasladosQuery({
      fetchPolicy: 'network-only',
      variables: {
        filters,
        pagination
      }
    })
    const dataTranslados = data?.traslados?.data ?? []
    const dataPagination = data?.traslados?.meta.pagination.total ?? 0
    return {
      dataTranslados,
      loading,
      error,
      dataPagination
    }
  }

  const Traslado = ({
    trasladoId
  }: {
    trasladoId: InputMaybe<string> | undefined
  }) => {
    const {
      data,
      loading: loadingTraslado,
      error: errorTraslado
    } = useTrasladoQuery({
      fetchPolicy: 'network-only',
      variables: {
        trasladoId
      }
    })
    const dataTraslado = data?.traslado?.data ?? ([] as TrasladoEntity)
    return {
      dataTraslado,
      loadingTraslado,
      errorTraslado
    }
  }

  return {
    Translados,
    Traslado
  }
}
