import React, { useRef, FC, Dispatch } from 'react'
import { MapPinIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { AeropuertoEntity } from 'generated/graphql'

interface Props {
  onClick?: () => void
  onChange?: (value: string) => void
  className?: string
  headingText?: string
  setValueSearch: Dispatch<React.SetStateAction<string>>
  dataAeropuertos?: AeropuertoEntity[]
  valueSearch: string
  value?: null
  setValue?: Dispatch<React.SetStateAction<null>>
}

const LocationInputMobile: FC<Props> = ({
  className = '',
  headingText = '¿A donde?',
  setValueSearch,
  dataAeropuertos,
  valueSearch
}) => {
  const containerRef = useRef(null)
  const inputRef = useRef(null)

  const handleSelectLocation = (item: string) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValueSearch(item)
      // onChange && onChange(item)
    }, 0)
  }

  const renderSearchValues = ({ heading }: { heading: string }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || 'Destinations'}
        </p>
        <div className="mt-3">
          {dataAeropuertos?.slice(0, 5).map((item) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer"
                onClick={() =>
                  handleSelectLocation(item.attributes?.aeropuerto!)
                }
                key={item.id}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{item.attributes?.aeropuerto!}</span>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={'Buscar destinos'}
            value={valueSearch}
            onChange={(e) => {
              setValueSearch(e.currentTarget.value)
            }}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {renderSearchValues({
            heading: 'Destinos populares'
          })}
        </div>
      </div>
    </div>
  )
}

export default LocationInputMobile
