import React, { FC } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import { useSessionStore } from 'store/session'

export interface AvatarProps {
  containerClassName?: string
  sizeClass?: string
  radius?: string
  imgUrl?: string
  userName?: string
  hasChecked?: boolean
  hasCheckedClass?: string
}

const Avatar: FC<AvatarProps> = () => {
  const auth = useSessionStore((state) => state.auth)
  const user = useSessionStore((state) => state.user)

  return (
    <>
      {auth ? (
        <div className="w-[36px] h-[36px] bg-primary-6000 rounded-full flex justify-center items-center text-white">
          <p className="font-medium text-[20px]">
            {user.username && user.username.charAt(0).toLocaleUpperCase()}
          </p>
        </div>
      ) : (
        <ButtonPrimary href="/login">Ingresar</ButtonPrimary>
      )}
    </>
  )
}

export default Avatar
