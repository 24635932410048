import { InputMaybe } from 'generated/graphql'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
interface DataBuyPerson {
  cantidadDePersonas?: number
  fechaDeCompra?: string | Date
  fechadelServicio?: string | Date | null
  total?: number
  tour?: InputMaybe<string> | undefined | null
  traslados?: InputMaybe<string> | undefined | null
  plan?: string
  guestAdults?: number
  guestChildren?: number
  guestInfants?: number
}
interface DataBuy {
  currenly: string
  dataBuyPerson: DataBuyPerson
  updateCurrenly: (currenly: string) => void
  updateDataBuyPerson: (data: DataBuyPerson) => void
}
export const DataBuyStore = create<DataBuy>()(
  persist(
    (set) => ({
      currenly: 'COP',
      dataBuyPerson: {
        cantidadDePersonas: 0,
        fechaDeCompra: new Date(),
        fechadelServicio: new Date(),
        total: 0,
        tour: null,
        traslados: null,
        plan: '',
        guestAdults: 1,
        guestChildren: 1,
        guestInfants: 1
      },
      updateCurrenly: (currenly) => set(() => ({ currenly })),
      updateDataBuyPerson: (data) => set(() => ({ dataBuyPerson: data }))
    }),
    {
      name: 'buy', // unique name,
      getStorage: () => localStorage // (optional) by default the 'localStorage' is used
    }
  )
)
