import DatePicker from 'react-datepicker'
import React, { Dispatch, FC } from 'react'
import DatePickerCustomHeaderTwoMonth from 'components/DatePickerCustomHeaderTwoMonth'
import DatePickerCustomDay from 'components/DatePickerCustomDay'

export interface StayDatesRangeInputProps {
  className?: string
  setStartDate?: Dispatch<React.SetStateAction<Date | null>>
  startDate?: Date | null
}

const DatesRangeInputOne: FC<StayDatesRangeInputProps> = ({
  className = '',
  setStartDate,
  startDate
}) => {
  const onChangeDate = (startDate: Date | null) => {
    if (setStartDate) {
      setStartDate(startDate)
    }
    // setEndDate(end)
  }

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` ¿Cuando es tu viaje?`}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        <DatePicker
          selected={startDate}
          onChange={(date) => onChangeDate(date)}
          startDate={startDate}
          // endDate={endDate}
          selectsRange={false}
          monthsShown={2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </div>
  )
}

export default DatesRangeInputOne
