import React, { FC, useEffect, useState } from 'react'
import Heading from 'shared/Heading/Heading'
import Nav from 'shared/Nav/Nav'
import NavItem from 'shared/NavItem/NavItem'
import { ReactNode } from 'react'
import { cutString } from 'utils/cutString'

export interface HeaderFilterProps {
  tabActive: string
  tabs: string[]
  heading: ReactNode
  subHeading?: ReactNode
  onClickTab: (item: string) => void
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = '',
  heading = '🎈 Latest Articles',
  onClickTab
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive)

  useEffect(() => {
    setTabActiveState(tabActive)
  }, [tabActive])

  const handleClickTab = (item: string) => {
    onClickTab && onClickTab(item)
    setTabActiveState(item)
  }

  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading}>{heading}</Heading>
      <div className="flex items-center justify-between">
        <Nav
          className="sm:space-x-2 mb-[5px]"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base navbar-tabs"
        >
          {tabs.map((item, index) => (
            <NavItem
              key={index}
              isActive={tabActiveState === item}
              onClick={() => handleClickTab(item)}
            >
              {cutString(15, item)}
            </NavItem>
          ))}
        </Nav>
 
      </div>
    </div>
  )
}

export default HeaderFilter
