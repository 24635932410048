import React, { FC, useState } from 'react'
// import facebookSvg from 'images/Facebook.svg'
// import twitterSvg from 'images/Twitter.svg'
// import googleSvg from 'images/Google.svg'
import { Helmet } from 'react-helmet'
import Input from 'shared/Input/Input'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import { Link, useNavigate } from 'react-router-dom'
import { Toast } from 'utils/toast'
import { useAuth } from 'service/useAuth'
import { useSessionStore } from 'store/session'
import Cookies from 'js-cookie'

export interface PageSignUpProps {
  className?: string
}

// const loginSocials = [
//   {
//     name: 'Continue with Facebook',
//     href: '#',
//     icon: facebookSvg
//   },
//   {
//     name: 'Continue with Twitter',
//     href: '#',
//     icon: twitterSvg
//   },
//   {
//     name: 'Continue with Google',
//     href: '#',
//     icon: googleSvg
//   }
// ]

const PageSignUp: FC<PageSignUpProps> = ({ className = '' }) => {
  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [cedula, setCedula] = useState('')
  const [name, setName] = useState('')
  const navigate = useNavigate()
  const authUpdate = useSessionStore((state) => state.authUpdate)
  const userUpdate = useSessionStore((state) => state.userUpdate)

  const { Register, loadingRegister } = useAuth()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (email.length === 0 || password.length === 0) {
      Toast({ type: 'error', message: 'Debes llenar todos los campos' })
      return
    }
    try {
      const res = await Register({
        email: email,
        password: password,
        username: userName,
        nameComplete: name,
        Whatsapp: whatsapp,
        cedula: cedula
      })
      Cookies.set('token', res.data?.register.jwt!)
      Cookies.set('user', JSON.stringify(res.data?.register.user))
      userUpdate(res.data?.register.user!)
      authUpdate(true)
      navigate('/')
    } catch (error) {
      Toast({ type: 'error', message: 'Error desconocido' })
    }
  }
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Crear cuenta || Travia</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Crear cuenta
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="grid grid-cols-1 gap-6"
            action="#"
            method="post"
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email
              </span>
              <Input
                type="email"
                value={email}
                placeholder="correo@correo.com"
                className="mt-1"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Nombre
              </span>
              <Input
                type="text"
                value={name}
                placeholder="nombre"
                className="mt-1"
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Username
              </span>
              <Input
                type="text"
                value={userName}
                placeholder="username"
                className="mt-1"
                onChange={(e) => {
                  setUserName(e.target.value)
                }}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Whatsapp
              </span>
              <Input
                type="text"
                value={whatsapp}
                placeholder="318xxxxxx"
                className="mt-1"
                onChange={(e) => {
                  setWhatsapp(e.target.value)
                }}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Cédula
              </span>
              <Input
                type="text"
                value={cedula}
                placeholder="101xxxxxx"
                className="mt-1"
                onChange={(e) => {
                  setCedula(e.target.value)
                }}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Contraseña
              </span>
              <Input
                type="password"
                value={password}
                className="mt-1"
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
            </label>
            <ButtonPrimary loading={loadingRegister} type="submit">
              Continuar
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            ¿Ya tienes una cuenta? {` `}
            <Link to="/login">Ingresar</Link>
          </span>
        </div>
      </div>
    </div>
  )
}

export default PageSignUp
