import React, { ReactNode } from 'react'

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ListingImageGallery from 'components/ListingImageGallery/ListingImageGallery'

import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const thisPathname = useLocation().pathname
  const [searchParams] = useSearchParams()
  const modal = searchParams?.get('modal')

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search)
    params.delete('modal')
    navigate(`${thisPathname}/?${params.toString()}`)
  }

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === 'PHOTO_TOUR_SCROLLABLE'}
        onClose={handleCloseModalImageGallery}
        // images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>

      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32">
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div>
    </div>
  )
}

export default DetailPagetLayout
