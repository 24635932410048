import { UsersPermissionsMe, UsersPermissionsUser } from 'generated/graphql'
import Cookies from 'js-cookie'
import { create } from 'zustand'

interface SessionState {
  auth: boolean
  authUpdate: (auth: boolean) => void
  user: UsersPermissionsMe
  userUpdate: (user: UsersPermissionsMe) => void
}
const user = Cookies.get('user')
export const useSessionStore = create<SessionState>()((set) => ({
  auth: false,
  user: user ? JSON.parse(user) : {},
  authUpdate: (auth) => set(() => ({ auth: auth })),
  userUpdate: (user) => set(() => ({ user: user }))
}))
