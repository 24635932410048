import React, { ReactNode, useEffect } from 'react'
import cx from 'classnames'
import { useBooleanState, usePrevious } from 'webrix/hooks'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Logo from '../../../public/logo-light-travia.png'

interface Props {
  children: ReactNode
}

const Offiline = ({ children }: Props) => {
  const {
    value: online,
    setFalse: setOffline,
    setTrue: setOnline
  } = useBooleanState(navigator.onLine)
  const previousOnline = usePrevious(online)
  useEffect(() => {
    if (!online) {
      return void disableBodyScroll(document.body)
    }

    enableBodyScroll(document.body)
  }, [online])

  useEffect(() => {
    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)

    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])
  return (
    <>
      <div
        className={`offline fixed w-[350px] sm:w-[380px]  top-0 z-[99] left-0 right-0 mx-auto pt-[20px]  animate__animated animate__faster animate__${
          online ? 'slideOutUp' : 'slideInDown'
        }`}
        style={
          previousOnline === online && online ? { display: 'none' } : void 0
        }
      >
        <div className="offline__content py-[15px] px-[20px] flex justify-center items-center flex-col gap-2 border-2 border-primary-6000 bg-white rounded-lg">
          <img
            src="/logo-light-travia.png"
            alt="logo"
            className="w-[50px] h-[50px]"
          />
          {/* <Logo /> */}
          <div className="offline__text">
            <p className=" text-center font-medium">Sin conexión a Internet</p>
            <p className=" text-center font-medium">
              Verifica tu conexión y vuelve a intentarlo
            </p>
          </div>
        </div>
      </div>
      <div
        className={cx(
          'offline__overlay fixed z-[3] top-0 left-0 w-[100vh] h-[100vw] opacity-0 pointer-events-none',
          {
            'offline__overlay--visible opacity-100 pointer-events-[unset]':
              !online
          }
        )}
      />
      {children}
    </>
  )
}

export default Offiline
