import React from 'react'
import { Helmet } from 'react-helmet'
import Header from 'components/Header/Header'

const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null)
  return (
    <>
      <Helmet>
        <title>Travia</title>
      </Helmet>
      <div className="relative z-40 hidden lg:block"></div>
      <Header className="shadow-sm dark:border-b dark:border-neutral-700" />
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  )
}

export default SiteHeader
