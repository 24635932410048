import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Maybe, Tour, UploadFileEntity } from 'generated/graphql'
import GallerySliderTours from 'components/GallerySlider/GallerySliderTours'

export interface ExperiencesCardProps {
  className?: string
  ratioClass?: string
  data: Tour
  size?: 'default' | 'small'
  id: Maybe<string> | undefined
}

const ToursCard: FC<ExperiencesCardProps> = ({
  size = 'default',
  className = '',
  data,
  id,
  ratioClass = 'aspect-w-3 aspect-h-3'
}) => {
  const { Galeria, ciudad, Nombre, precio } = data

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden will-change-transform">
        <GallerySliderTours
          uniqueID={`ExperiencesCard_${id}`}
          ratioClass={ratioClass}
          galleryImgs={Galeria?.data as UploadFileEntity[]}
          href={`/tour-detail/${id}`}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
        {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className={size === 'default' ? 'py-4 space-y-4' : 'py-3 space-y-2'}>
        <div className="space-y-2">
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === 'default' && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">
              {ciudad} -{' '}
              {data.categoria_tours?.data.length === 0
                ? 'Playa'
                : data.categoria_tours?.data[0].attributes?.nombre}
            </span>
          </div>

          <div className="flex items-center space-x-2">
            {/* {true && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${
                size === 'default' ? 'text-base' : 'text-base'
              }`}
            >
              <span className="line-clamp-1">{Nombre}</span>
            </h2>
          </div>
        </div>
        <div className="border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            ${precio}
            {` `}
            {size === 'default' && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /person
              </span>
            )}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`nc-ExperiencesCard group relative ${className}`}
      data-nc-id="ExperiencesCard"
    >
      {renderSliderGallery()}
      <Link to={`/tour-detail/${id}`}>{renderContent()}</Link>
    </div>
  )
}

export default ToursCard
