import React, { FC, useState } from 'react'


import { useLocation, useNavigate, useParams } from 'react-router-dom'
import GuestsInputComponente from 'components/GuestsInputComponente'
import { DataBuyStore } from 'store/dataBuy'
import LocationGoogle from 'components/LocationGoogle'
import queryString from 'query-string'
import StayDatesRangeInputOne from '../StayDatesRangeInputOne'

export interface ExperiencesSearchFormProps {}

const ExperiencesSearchForm: FC<ExperiencesSearchFormProps> = () => {
  const updateDataBuyPerson = DataBuyStore((state) => state.updateDataBuyPerson)
  const dataBuyPerson = DataBuyStore((state) => state.dataBuyPerson)

  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  const { fecha } = useParams()
  const navigate = useNavigate()
  const [value, setValue] = useState<any | null>(
    queryParams.dataCiudad ? queryParams.dataCiudad : null
  )

  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState<
    number | undefined
  >(dataBuyPerson.guestAdults)
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState<
    number | undefined
  >(dataBuyPerson.guestChildren)
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState<
    number | undefined
  >(dataBuyPerson.guestInfants)
  const [startDate, setStartDate] = useState<Date | null>(
    fecha ? new Date(fecha) : new Date()
  )

  const handleChange = (value: string) => {
    setValue(value)
  }

  // console.log(value?.value.structured_formatting.main_text)
  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex flex-col md:flex-row  rounded-3xl md:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <LocationGoogle
          placeHolder="Ingresa un hotel o dirección adónde quieras ir"
          desc="Destino"
          className="flex-1"
          divHideVerticalLineClass="-inset-x-0.5"
          value={value}
          handleChange={handleChange}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        {/* <ExperiencesDateSingleInput className="flex-1" /> */}
        <StayDatesRangeInputOne
          className="flex-1 "
          startDate={startDate}
          setStartDate={setStartDate}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInputComponente
          setGuestChildrenInputValue={setGuestChildrenInputValue}
          setGuestAdultsInputValue={setGuestAdultsInputValue}
          setGuestInfantsInputValue={setGuestInfantsInputValue}
          guestChildrenInputValue={guestChildrenInputValue!}
          guestAdultsInputValue={guestAdultsInputValue!}
          guestInfantsInputValue={guestInfantsInputValue!}
          hasButtonSubmit
          handleClick={() => {
            navigate(`/tours?dataCiudad=${value ? value : ''}`)
            updateDataBuyPerson({
              ...dataBuyPerson,
              guestAdults: guestAdultsInputValue,
              guestChildren: guestChildrenInputValue,
              guestInfants: guestInfantsInputValue,
              cantidadDePersonas:
                guestChildrenInputValue! + guestAdultsInputValue!
            })
          }}
        />
      </form>
    )
  }

  return renderForm()
}

export default ExperiencesSearchForm
