import React, { FC, useEffect, useState } from "react";
import { Squares2X2Icon } from "@heroicons/react/24/outline";

import Badge from "shared/Badge/Badge";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTours } from "service/useTours";
import { VariantsItem } from "interface";
import { RenderLoading, Show } from "shared/Show";
import { v4 } from "uuid";
import { useSessionStore } from "store/session";
import { DataBuyStore } from "store/dataBuy";
import { useUser } from "service/useUser";
import { cutString } from "utils/cutString";
import GuestsInputComponente from "components/GuestsInputComponente";
import MobileFooterStickyCustom from "components/MobileFooterStickyCustom";
import StayDatesRangeInputOne from "./StayDatesRangeInputOne";
import DetailPagetLayout from "./Layout";

const ListingExperiencesDetail: FC<{}> = () => {
  // ESTADO PARA LA FECHA DEL TOUR
  const [startDate, setStartDate] = useState<Date | null>(new Date());

  // ESTADO PARA ACTUALIZAR LA VARIANTE
  const [selectVariante, setSelectVariante] = useState({
    duracion: "",
    disponibilidad: "",
    descripcion: "",
    precio: 0,
  });
  const currenly = DataBuyStore((state) => state.currenly);
  const updateDataBuyPerson = DataBuyStore(
    (state) => state.updateDataBuyPerson
  );
  const dataBuyPerson = DataBuyStore((state) => state.dataBuyPerson);

  // ESTADO PARA SABER CUANTOS PERSONAS HAY
  const [guestAdultsTourInputValue, setGuestAdultsTourInputValue] = useState(
    dataBuyPerson.guestAdults
  );
  const [guestChildrenTourInputValue, setGuestChildrenTourInputValue] =
    useState(dataBuyPerson.guestChildren);
  const [guestInfantsTourInputValue, setGuestInfantsTourInputValue] = useState(
    dataBuyPerson.guestInfants
  );

  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const { id } = useParams();
  const { Tour } = useTours();
  const { dataTour, loadingTour } = Tour({ tourId: id });

  const auth = useSessionStore((state) => state.auth);
  const user = useSessionStore((state) => state.user);

  const { User } = useUser();
  const { dataUser } = User(user.id);

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  // USEEFFECT PARA ACTUALIZAR LA VARIANTE POR DEFECTO
  useEffect(() => {
    if (dataTour) {
      const variante = dataTour.attributes?.variantes[0];
      setSelectVariante({
        duracion: variante && variante.Duracion,
        disponibilidad: variante && variante.Disponibilidad,
        descripcion: variante && variante.descripcion,
        precio: variante && variante.precio,
      });
    }
  }, [loadingTour]);

  const handleReserva = async () => {
    if (!auth) {
      router("../login");
      return;
    }
    const res = await fetch("https://api.travia.com.co/api/procesarPago", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nombre: dataTour.attributes?.Nombre,
        description: dataTour.attributes?.DescripcionCorta,
        idProducto: dataTour.id,
        area_code_payer: "57",
        currency: currenly,
        cantidad: 1,
        numberIDPayer: user.id,
        category_id: "tour",
        typeidPayer: "CC",
        precio:
          +selectVariante.precio *
          (guestAdultsTourInputValue! + guestChildrenTourInputValue!),
        urlImagen: dataTour.attributes?.ImagenDestacada.data?.attributes?.url,
        descripcion: dataTour.attributes?.DescripcionCorta,
        nombreCliente: dataUser.attributes?.nameComplete,
        apellidoCliente: dataUser.attributes?.username,
        emailCliente: dataUser.attributes?.email,
        telefonoCliente: 3112571830,
        codigoPostal: "730001",
        direccion: "calle 20",
        numeroDireccion: 1258,
        numeroIdentificacion: dataUser.attributes?.cedula,
        coupon_amount: 100,
      }),
    });
    const data = await res.json();
    if (data.init) {
      updateDataBuyPerson({
        cantidadDePersonas:
          guestAdultsTourInputValue! + guestChildrenTourInputValue!,
        fechaDeCompra: new Date(),
        fechadelServicio: startDate,
        total:
          +selectVariante.precio *
          (guestAdultsTourInputValue! + guestChildrenTourInputValue!),
        tour: id!,
        plan: "tour",
        guestAdults: guestAdultsTourInputValue,
        guestChildren: guestChildrenTourInputValue,
        guestInfants: guestInfantsTourInputValue,
      });
      router(`../checkout/${data.id}`);
    }
  };
  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <div className="flex gap-4 flex-wrap">
            {dataTour.attributes?.etiquetas?.split(",").map((item) => (
              <Badge key={item} color="pink" name={item} />
            ))}
          </div>
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {dataTour.attributes?.Nombre}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {/* <StartRating />
          <span>·</span> */}
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {dataTour.attributes?.ciudad}</span> -
            <span className="ml-1">
              {" "}
              {dataTour.attributes?.categoria_tours?.data.length === 0
                ? "Playa"
                : dataTour.attributes?.categoria_tours?.data[0].attributes
                    ?.nombre}
            </span>
          </span>
        </div>
        <span className="block mt-3 text-neutral-500 dark:text-neutral-400 mb-4">
          <div
            dangerouslySetInnerHTML={{
              __html: dataTour?.attributes?.Descripcion!,
            }}
          />
        </span>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-clock text-2xl"></i>
            <span className="">{selectVariante.duracion}</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-user-friends text-2xl"></i>
            <span className="">
              {dataTour.attributes?.contieneStock &&
                `Cupos ${dataTour.attributes?.cantidadStock}`}
            </span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-bus-alt text-2xl"></i>
            <span className="">{selectVariante.disponibilidad}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          Mira las opciones de esta actividad y !Elige la tuya!
        </h2>
        {dataTour.attributes?.variantes.map((item: VariantsItem) => (
          <div key={v4()} className="listingSection__wrap">
            <div>
              <div className="flex justify-between gap-2 items-center">
                <h2 className="text-2xl font-semibold">{item.nombre}</h2>
                <h2 className="text-2xl font-semibold">${item.precio}</h2>
              </div>

              <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300 mt-2">
                <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                  <i className="las la-clock text-2xl"></i>
                  <span className="">Duracion {item.Duracion}</span>
                </div>
                <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                  <i className="las la-user-friends text-2xl"></i>
                  <span className="">Disponibilidad {item.Disponibilidad}</span>
                </div>
              </div>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            {/* INCLUDE  */}
            <div className="flex justify-between">
              <div>
                <h4 className="text-lg font-semibold">Incluye</h4>
                <div className="flex flex-col gap-2">
                  {item.incluye.map((inc) => (
                    <span
                      key={v4()}
                      className="block mt-3 text-neutral-500 dark:text-neutral-400"
                    >
                      {inc.item}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <h4 className="text-lg font-semibold">No incluye</h4>
                <div className="flex flex-col gap-2">
                  {item.noIncluye.map((noInc) => (
                    <span
                      key={v4()}
                      className="block mt-3 text-neutral-500 dark:text-neutral-400"
                    >
                      {noInc.item}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h4 className="text-lg font-semibold">Descripción</h4>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {item.descripcion}
              </span>
            </div>
            <ButtonPrimary
              onClick={() =>
                setSelectVariante({
                  duracion: item.Duracion,
                  disponibilidad: item.Disponibilidad,
                  descripcion: item.descripcion,
                  precio: +item.precio,
                })
              }
            >
              Seleccionar
            </ButtonPrimary>
          </div>
        ))}
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Ubicación</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {dataTour.attributes?.ciudad}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDu5MNkinB4TmnyRixzuu9SIbUeZY-dPGo&q=${
                dataTour && dataTour.attributes?.ciudad
              }`}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Información</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Medidas de higiene</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {dataTour.attributes?.medidasHigiene || ""}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Informacion general</h4>
          {dataTour.attributes?.informacionGeneral
            .split("/")
            .map((info: string) => (
              <span
                key={v4()}
                className="block mt-3 text-neutral-500 dark:text-neutral-400"
              >
                {info.replace(",", "") || ""}
              </span>
            ))}
        </div>
        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Politicas de cancelacion</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {dataTour.attributes?.politicasdeCancelacion || ""}
          </span>
        </div>
        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Antes de asistir</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {dataTour.attributes?.antesdeAsistir || ""}
          </span>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl lg:block hidden">
        <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
          {cutString(30, selectVariante.descripcion)}
        </span>
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            ${selectVariante.precio}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /persona
            </span>
          </span>
        </div>

        {/* FORM */}
        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInputOne
            className="flex-1 z-[11]"
            startDate={startDate}
            setStartDate={setStartDate}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInputComponente
            className="flex-1"
            setGuestChildrenInputValue={setGuestChildrenTourInputValue}
            setGuestAdultsInputValue={setGuestAdultsTourInputValue}
            setGuestInfantsInputValue={setGuestInfantsTourInputValue}
            guestInfantsInputValue={guestInfantsTourInputValue!}
            guestAdultsInputValue={guestAdultsTourInputValue!}
            guestChildrenInputValue={guestChildrenTourInputValue!}
          />
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              ${selectVariante.precio} x{" "}
              {guestAdultsTourInputValue! + guestChildrenTourInputValue!}{" "}
              Personas
            </span>
            <span>
              $
              {+selectVariante.precio *
                (guestAdultsTourInputValue! + guestChildrenTourInputValue!)}
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Cargo por servicio</span>
            <span>$0</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>
              $
              {+selectVariante.precio *
                (guestAdultsTourInputValue! + guestChildrenTourInputValue!)}
            </span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary onClick={handleReserva}>Reserve</ButtonPrimary>

        {/* MENU PARA RESERVAR EN MOBILE */}
      </div>
    );
  };
  return (
    <Show condition={!loadingTour} isDefault={<RenderLoading />}>
      <div className={` nc-ListingExperiencesDetailPage `}>
        {/* SINGLE HEADER */}
        <header className="rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-3 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={handleOpenModalImageGallery}
            >
              <img
                alt="photos 1"
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                src={
                  dataTour?.attributes?.ImagenDestacada.data?.attributes?.url
                }
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {dataTour.attributes?.Galeria?.data
              .filter((_, i) => i >= 1 && i < 4)
              .map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 2 ? "block" : ""
                  }`}
                >
                  <div className="aspect-w-4 aspect-h-3">
                    <img
                      alt="photos"
                      className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl"
                      src={item.attributes?.url || ""}
                      sizes="400px"
                    />
                  </div>

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={handleOpenModalImageGallery}
            >
              <Squares2X2Icon className="h-5 w-5" />
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Mostrar todas las imagenes
              </span>
            </div>
          </div>
        </header>

        {/* MAIn */}

        <main className="relative z-10 mt-11 flex flex-col lg:flex-row ">
          {/* CONTENT */}
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
            {renderSection1()}
            {<div className="lg:hidden">{renderSidebar()}</div>}
            {/* {renderSection2()} */}
            {/* {renderSection3()} */}
            {/* <SectionDateRange /> */}

            {renderSection5()}
            {/* {renderSection6()} */}
            {renderSection7()}
            {renderSection8()}
            <MobileFooterStickyCustom
              handleReserva={handleReserva}
              precio={
                selectVariante.precio *
                (guestAdultsTourInputValue! + guestChildrenTourInputValue!)
              }
              duracion={selectVariante.duracion}
            />
          </div>

          {/* SIDEBAR */}
          <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
            <div className="sticky top-28">{renderSidebar()}</div>
          </div>
        </main>
      </div>
    </Show>
  );
};

export default function DetalleTourPage() {
  return (
    <DetailPagetLayout>
      <ListingExperiencesDetail />
    </DetailPagetLayout>
  );
}
