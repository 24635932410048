import React, { Dispatch, useState } from 'react'
import GuestsInput from '../GuestsInput'
import { GuestsObject } from 'components/HeroSearchForm/type'
import moment from 'moment'
import DatesRangeInputOne from '../DatesRangeInputOne'
import { AeropuertoEntity } from 'generated/graphql'
import { useSearch } from 'service/useSearch'
import LocationInputMobile from 'components/LocationInputMobile'

interface Props {
  setValueSearch?: Dispatch<React.SetStateAction<string>>
  setGuestAdultsInputValue: Dispatch<React.SetStateAction<number>>
  setGuestChildrenInputValue: Dispatch<React.SetStateAction<number>>
  setGuestInfantsInputValue: Dispatch<React.SetStateAction<number>>
  guestInfantsInputValue: number
  guestAdultsInputValue: number
  guestChildrenInputValue: number
  valueSearch?: string
  variante?: 'tours' | 'traslados'
  value?: null
  setValue?: Dispatch<React.SetStateAction<null>>
}

const StaySearchForm = ({
  setValueSearch,
  valueSearch,
  setGuestInfantsInputValue,
  setGuestAdultsInputValue,
  setGuestChildrenInputValue,
  guestInfantsInputValue,
  guestAdultsInputValue,
  guestChildrenInputValue
}: Props) => {
  //
  const [fieldNameShow, setFieldNameShow] = useState<
    'location' | 'dates' | 'guests'
  >('location')
  //

  const [locationInputTo, setLocationInputTo] = useState('')
  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0
  })
  const [startDate, setStartDate] = useState<Date | null>(new Date())

  // DATA DE AEROPUERTOS
  const { dataAeropuertos } = useSearch({
    filters: {
      aeropuerto: {
        contains: valueSearch
      }
    }
  })

  const renderInputLocation = () => {
    const isActive = fieldNameShow === 'location'
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('location')}
          >
            <span className="text-neutral-400">Donde</span>
            <span>{locationInputTo || 'Ubicación'}</span>
          </button>
        ) : (
          <LocationInputMobile
            setValueSearch={setValueSearch!}
            dataAeropuertos={dataAeropuertos as AeropuertoEntity[]}
            valueSearch={valueSearch!}
            onChange={(value) => {
              setLocationInputTo(value)
              setFieldNameShow('dates')
              // setValueSearch(value)
            }}
          />
        )}
      </div>
    )
  }

  const renderInputDates = () => {
    const isActive = fieldNameShow === 'dates'

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow('dates')}
          >
            <span className="text-neutral-400">Cuando</span>

            <span>
              {startDate ? `${moment(startDate).format('MMM DD')}` : 'Add date'}
            </span>
          </button>
        ) : (
          <DatesRangeInputOne
            startDate={startDate}
            setStartDate={setStartDate}
          />
        )}
      </div>
    )
  }

  const renderInputGuests = () => {
    const isActive = fieldNameShow === 'guests'
    let guestSelected = ''
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const guest =
        (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0)
      guestSelected += `${guest} guests`
    }

    if (guestInput.guestInfants) {
      guestSelected += `, ${guestInput.guestInfants} infants`
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('guests')}
          >
            <span className="text-neutral-400">Quien</span>
            <span>{guestSelected || `Agregar persona`}</span>
          </button>
        ) : (
          <GuestsInput
            setGuestInfantsInputValue={setGuestInfantsInputValue}
            setGuestChildrenInputValue={setGuestChildrenInputValue}
            setGuestAdultsInputValue={setGuestAdultsInputValue}
            defaultValue={guestInput}
            onChange={setGuestInput}
            guestInfantsInputValue={guestInfantsInputValue}
            guestChildrenInputValue={guestChildrenInputValue}
            guestAdultsInputValue={guestAdultsInputValue}
          />
        )}
      </div>
    )
  }

  return (
    <div>
      <div className="w-full space-y-5">
        {/*  */}
        {renderInputLocation()}
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {renderInputGuests()}
      </div>
    </div>
  )
}

export default StaySearchForm
