import { DateRage } from 'components/HeroSearchForm/type'

const converSelectedDateToString = ([startDate, endDate]: DateRage) => {
  const dateString =
    startDate?.toLocaleDateString('en', {
      month: 'short',
      day: '2-digit'
    }) ||
    '' +
      (endDate
        ? ' - ' +
          endDate?.toLocaleDateString('en', {
            month: 'short',
            day: '2-digit'
          })
        : '')
  return dateString
}

export default converSelectedDateToString
