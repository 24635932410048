import React, { FC, ReactNode, useEffect, useState } from 'react'
import { DEMO_STAY_LISTINGS } from 'data/listings'
import { StayDataType } from 'data/types'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import HeaderFilter from './HeaderFilter'
import StayCard from 'components/StayCard/StayCard'
import { useTours } from 'service/useTours'
import ToursCard from 'components/ToursCard'
import { Tour } from 'generated/graphql'
import { v4 } from 'uuid'
import { RenderLoading, Show } from 'shared/Show'
import Heading from 'shared/Heading/Heading'
import { useDataCmsStore } from 'store/dataCms'

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8)

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[]
  gridClass?: string
  heading?: ReactNode
  subHeading?: ReactNode
  headingIsCenter?: boolean
  tabs?: string[]
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = '',
  heading = '¡Explora Nuestros Tours Emblemáticos! 🌟🌍',
  subHeading = 'Descubre los Destinos más Icónicos y Vive Experiencias Inolvidables',
  headingIsCenter,
  tabs = ['New York', 'Tokyo', 'Paris', 'London']
}) => {
  const [tabActiveState, setTabActiveState] = useState('')
  // const renderCard = (stay: StayDataType) => {
  //   return <StayCard key={stay.id} data={stay} />
  // }
  const dataCms = useDataCmsStore((state) => state.dataCms)
  const { Tours } = useTours()
  const { dataTours, loadingTours } = Tours({
    filters: {
      ciudad: {
        contains: tabActiveState
      }
    }
  })

  useEffect(() => {
    setTabActiveState(tabs[0])
  }, [tabs])

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={tabs[0]}
        subHeading={
          dataCms.descripcionTours.length !== 0
            ? dataCms.descripcionTours
            : subHeading
        }
        tabs={tabs}
        heading={
          dataCms.tituloTours.length !== 0 ? dataCms.tituloTours : heading
        }
        onClickTab={(item) => {
          setTabActiveState(item)
        }}
      />
      <Show condition={!loadingTours} isDefault={<RenderLoading />}>
        {dataTours.length !== 0 ? (
          <div
            className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
          >
            {dataTours?.map((tour) => (
              <ToursCard
                key={v4()}
                data={tour.attributes as Tour}
                id={tour.id}
              />
            ))}
          </div>
        ) : (
          <Heading>No hay Tours</Heading>
        )}
      </Show>
      {/* <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary href="#">Ver más tours</ButtonPrimary>
      </div> */}
    </div>
  )
}

export default SectionGridFeaturePlaces
