import ButtonPrimary from 'shared/Button/ButtonPrimary'
import React from 'react'
import { Helmet } from 'react-helmet'
import NcImage from 'shared/NcImage/NcImage'
import FailurePng from 'images/failure.png'

const Failure: React.FC = () => (
  <div className="nc-Page404">
    <Helmet>
      <title>Travia | Pago rechazado</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2">
        <NcImage src={FailurePng} />
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          Le informamos que su pedido no ha sido procesado debido a un problema
          con el pago. Por favor, verifique los detalles de su método de pago y
          complete el proceso para que podamos proceder con el envío de su
          pedido.
        </span>
        <div className="pt-8">
          <ButtonPrimary href="/">Volver al inicio</ButtonPrimary>
        </div>
      </header>
    </div>
  </div>
)

export default Failure
