import React, { FC } from 'react'
import ButtonCircle from 'shared/Button/ButtonCircle'
import rightImg from 'images/SVG-subcribe2.png'
import NcImage from 'shared/NcImage/NcImage'
import Badge from 'shared/Badge/Badge'
import Input from 'shared/Input/Input'
import { useDataCmsStore } from 'store/dataCms'

export interface SectionSubscribe2Props {
  className?: string
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = '' }) => {
  const dataCms = useDataCmsStore((state) => state.dataCms)
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">{dataCms.tituloBoletin}</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          {dataCms.descripcionBoletin}
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {dataCms.item1Boletin}
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {dataCms.item2Boletin}
            </span>
          </li>
        </ul>
        <form className="mt-10 relative max-w-sm">
          <Input
            required
            aria-required
            placeholder="ingresar su email"
            type="email"
            rounded="rounded-full"
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  )
}

export default SectionSubscribe2
