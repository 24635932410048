import React, { FC } from 'react'
import rightImgPng from 'images/our-features.webp'
import NcImage from 'shared/NcImage/NcImage'
import Badge from 'shared/Badge/Badge'
import { useDataCmsStore } from 'store/dataCms'

export interface SectionOurFeaturesProps {
  className?: string
  rightImg?: string
  type?: 'type1' | 'type2'
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = 'lg:py-14',
  rightImg = rightImgPng,
  type = 'type1'
}) => {
  const dataCms = useDataCmsStore((state) => state.dataCms)

  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === 'type1' ? 'lg:flex-row' : 'lg:flex-row-reverse'
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === 'type1' ? 'lg:pl-16' : 'lg:pr-16'
        }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          {dataCms.sloganBeneficios}
        </span>
        <h2 className="font-semibold text-4xl mt-5">
          {dataCms.tituloBeneficios}
        </h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge name={dataCms.tag1Beneficios} />
            <span className="block text-xl font-semibold">
              {dataCms.itemTitulo1Beneficios}
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              {dataCms.itemDescripcion1Beneficios}
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name={dataCms.tag2Beneficios} />
            <span className="block text-xl font-semibold">
              {dataCms.itemTitulo2Beneficios}
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              {dataCms.itemDescripcion2Beneficios}
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="red" name={dataCms.tag3Beneficios} />
            <span className="block text-xl font-semibold">
              {dataCms.itemTitulo3Beneficios}
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              {dataCms.itemDescripcion3Beneficios}
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SectionOurFeatures
