import React, { FC } from 'react'

import Heading2 from 'components/Heading/Heading2'
import Heading from 'shared/Heading/Heading'
import CardAirpot from 'components/CardAirpot'
import { v4 } from 'uuid'
import { Traslado, TrasladoEntity } from 'generated/graphql'

export interface SectionGridFilterCardProps {
  className?: string
  data?: TrasladoEntity[]
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = '',
  data
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading={`Traslados encontrados ${data?.length}`}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3"></span>
        }
      />

      <div className="mb-8 lg:mb-11">{/* <TabFilters /> */}</div>
      {data!.length === 0 ? (
        <Heading>No hay Traslados disponibles</Heading>
      ) : (
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {data!.map((airpot: any) => (
            <CardAirpot
              key={v4()}
              // data={airpot?.attributes!}
              data={airpot?.attributes! as Traslado}
              id={airpot?.id}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default SectionGridFilterCard
