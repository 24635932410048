import { InputMaybe, useUsersPermissionsUserQuery } from 'generated/graphql'

export const useUser = () => {
  const User = (usersPermissionsUserId: InputMaybe<string> | undefined) => {
    const { data, loading, error } = useUsersPermissionsUserQuery({
      fetchPolicy: 'network-only',
      variables: {
        usersPermissionsUserId
      }
    })
    const dataUser = data?.usersPermissionsUser?.data ?? {}
    return {
      dataUser,
      loading,
      error
    }
  }

  return { User }
}
