import SectionHero from 'components/SectionHero/SectionHero'
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories'
import React, { useMemo } from 'react'
import SectionOurFeatures from 'components/SectionOurFeatures/SectionOurFeatures'
import SectionGridFeaturePlaces from './SectionGridFeaturePlaces'
import SectionHowItWork from 'components/SectionHowItWork/SectionHowItWork'
import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import SectionVideos from './SectionVideos'
import SectionClientSay from 'components/SectionClientSay/SectionClientSay'
import { useCities } from 'service/useCities'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'

function PageHome() {
  const { Cities } = useCities()
  const { dataCities, loadingCities } = Cities()
  const tabs = useMemo(
    () => dataCities.map((item) => item.attributes?.nombre),
    [loadingCities]
  )
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        {/* SECTION 1 */}
        <SectionSliderNewCategories uniqueClassName="PageHome_s1" />

        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces tabs={tabs as string[]} />
        </div>

        {/* SECTION */}
        <SectionHowItWork />

        {/* SECTION */}
        <SectionSubscribe2 />

        {/* SECTION */}
        <SectionVideos />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
      </div>
    </div>
  )
}

export default PageHome
