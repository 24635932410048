import Label from 'components/Label/Label'
import React, { FC, useEffect, useState } from 'react'
// import Avatar from 'shared/Avatar/Avatar'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Input from 'shared/Input/Input'
// import Select from 'shared/Select/Select'
// import Textarea from 'shared/Textarea/Textarea'
import CommonLayout from './CommonLayout'
import { Helmet } from 'react-helmet'
import { useSessionStore } from 'store/session'
import { Toast } from 'utils/toast'
import { Maybe, UsersPermissionsMe } from 'generated/graphql'
import { useAuth } from 'service/useAuth'
import Cookies from 'js-cookie'
import { useUser } from 'service/useUser'

export interface AccountPageProps {
  className?: string
}

const AccountPage: FC<AccountPageProps> = ({ className = '' }) => {
  const user = useSessionStore((state) => state.user)
  const userUpdate = useSessionStore((state) => state.userUpdate)

  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [name, setName] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [cedula, setCedula] = useState('')

  const { User } = useUser()

  const { dataUser, loading } = User(user.id)

  const { UpdateUser, loadingUpdateUsers } = useAuth()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const res = await UpdateUser({
        id: user.id,
        data: {
          email: email,
          username: userName,
          nameComplete: name,
          Whatsapp: whatsapp,
          cedula: cedula
        }
      })
      const newUser = {
        ...res.dataUser?.updateUsersPermissionsUser.data?.attributes,
        id: user.id
      }
      Cookies.set('user', JSON.stringify(newUser))
      userUpdate(newUser as UsersPermissionsMe)
    } catch (error) {
      Toast({ type: 'error', message: 'Error desconocido' })
    }
  }
  useEffect(() => {
    if (!loading) {
      setEmail(dataUser.attributes?.email!)
      setName(dataUser.attributes?.nameComplete!)
      setUserName(dataUser.attributes?.username!)
      setWhatsapp(dataUser.attributes?.Whatsapp!)
      setCedula(`${dataUser.attributes?.cedula!}`)
    }
  }, [loading])

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Trivia | Historial</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Actualizar información</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="w-[36px] h-[36px] bg-primary-6000 rounded-full flex justify-center items-center">
                <p className="font-medium text-[20px]">
                  {user.username && user.username.charAt(0).toLocaleUpperCase()}
                </p>
              </div>
            </div>
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6"
            >
              <div>
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  className="mt-1.5"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </div>
              {/* ---- */}
              <div>
                <Label>Name</Label>
                <Input
                  name="name"
                  className="mt-1.5"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                />
              </div>
              {/* ---- */}

              <div>
                <Label>Username</Label>
                <Input
                  name="username"
                  className="mt-1.5"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value)
                  }}
                />
              </div>
              {/* ---- */}
              <div>
                <Label>Whatsapp</Label>
                <Input
                  name="whatsapp"
                  className="mt-1.5"
                  value={whatsapp}
                  onChange={(e) => {
                    setWhatsapp(e.target.value)
                  }}
                />
              </div>
              {/* ---- */}
              <div>
                <Label>Cédula</Label>
                <Input
                  name="cedula"
                  className="mt-1.5"
                  value={cedula}
                  onChange={(e) => {
                    setCedula(e.target.value)
                  }}
                />
              </div>
              {/* ---- */}

              <div className="pt-2">
                <ButtonPrimary loading={loadingUpdateUsers}>
                  Actualizar información
                </ButtonPrimary>
              </div>
            </form>
          </div>
        </div>
      </CommonLayout>
    </div>
  )
}

export default AccountPage
