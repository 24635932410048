import React, { useEffect, useState } from 'react'

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false)

  const [promptInstall, setPromptInstall] = useState(null)

  useEffect(() => {
    const handler = (e: any) => {
      e?.preventDefault()
      setSupportsPWA(true)
      setPromptInstall(e)
      console.log(promptInstall)
    }
    window.addEventListener('beforeinstallprompt', handler)

    return () => window.removeEventListener('transitionend', handler)
  }, [])

  // useEffect(() => {
  //   const handler = (e: any) => {
  //     e?.preventDefault()
  //     console.log('we are being triggered :D')
  //     alert('Lo soporta')
  //     setSupportsPWA(true)
  //     setPromptInstall(e)
  //   }

  //   const timer = setTimeout(() => {
  //     console.log('entre')
  //     window.addEventListener('beforeinstallprompt', handler)
  //   }, 3000) // Espera 3 segundos antes de agregar el evento

  //   return () => {
  //     clearTimeout(timer) // Limpia el temporizador si el componente se desmonta antes de que se ejecute
  //     window.removeEventListener('transitionend', handler)
  //   }
  // }, [])

  const onClick = async (evt: { preventDefault: () => void }) => {
    evt.preventDefault()
    if (!promptInstall) {
      return
    }
    ;(promptInstall as any).prompt()

    setSupportsPWA(false)
  }
  if (!supportsPWA) {
    return null
  }
  return (
    <>
      <div
        className={`${
          supportsPWA ? 'right-[0.5rem]' : 'right-[-100%]'
        } fixed px-2 py-4  top-2 md:top-auto md:bottom-2 z-50 bg-white border-2 border-[#4338CA]  rounded-md dark:bg-[#111827] shadow-md transition-all duration-1000`}
      >
        {' '}
        <p className="text-[#111827]   text-sm dark:text-white font-medium mb-2">
          ¿Te gustaría instalar nuestra aplicación?
        </p>
        <div className="flex justify-center gap-2">
          <button
            className="px-2 py-1 border-2  border-[#4338CA] text-[#111827]disabled:bg-opacity-70  hover:bg-primary-700 rounded-lg text- dark:text-white text-sm hover:text-white"
            onClick={() => setSupportsPWA(false)}
          >
            Cancelar
          </button>
          <button
            className="px-2 py-1 bg-primary-6000 hover:bg-primary-700 border-2 border-[#4338CA]   rounded-lg text-white text-sm"
            onClick={onClick}
          >
            Instalar
          </button>
        </div>
      </div>
    </>
  )
}

export default InstallPWA
