import React, { useEffect } from 'react'
import { Routes, Route, HashRouter } from 'react-router-dom'
import { Page } from './types'
import ScrollToTop from './ScrollToTop'
import Footer from 'shared/Footer/Footer'
import PageHome from 'containers/PageHome/PageHome'
import Page404 from 'containers/Page404/Page404'

import CheckOutPage from 'containers/CheckOutPage/CheckOutPage'
import PayPage from 'containers/PayPage/PayPage'
import AuthorPage from 'containers/AuthorPage/AuthorPage'
import AccountPage from 'containers/AccountPage/AccountPage'
import AccountPass from 'containers/AccountPage/AccountPass'
import AccountSavelists from 'containers/AccountPage/AccountSavelists'
import AccountBilling from 'containers/AccountPage/AccountBilling'
import PageContact from 'containers/PageContact/PageContact'
import PageAbout from 'containers/PageAbout/PageAbout'
import PageSignUp from 'containers/PageSignUp/PageSignUp'
import PageLogin from 'containers/PageLogin/PageLogin'
import PageSubcription from 'containers/PageSubcription/PageSubcription'

import SiteHeader from 'containers/SiteHeader'
import FooterNav from 'components/FooterNav'
import useWindowSize from 'hooks/useWindowResize'

import { useSessionStore } from 'store/session'
import { findCookie } from 'utils/findCookie'
import { useCmsQuery } from 'generated/graphql'
import { useDataCmsStore } from 'store/dataCms'
import TourPage from 'containers/Tours'
import TrasladosPage from 'containers/Traslados'
import DetalleTourPage from 'containers/Tours/DetalleTour'
import Failure from 'containers/Failure'
import Pending from 'containers/Pending'

export const pages: Page[] = [
  { path: '/', exact: true, component: PageHome },
  { path: '/#', exact: true, component: PageHome },
  { path: '/home-1-header-2', exact: true, component: PageHome },

  //

  {
    path: '/tours',
    component: TourPage
  },
  // PAGINA FAILURE
  { path: '/failure', component: Failure },
  // PAGINA PENDING
  { path: '/pending', component: Pending },

  {
    path: '/tour-detail/:id',
    component: DetalleTourPage
  },
  //
  { path: '/traslados', component: TrasladosPage },
  //
  { path: '/checkout', component: CheckOutPage },
  { path: '/checkout/:id', component: CheckOutPage },
  { path: '/pay-done', component: PayPage },
  //
  { path: '/author', component: AuthorPage },
  { path: '/account', component: AccountPage },
  { path: '/account-password', component: AccountPass },
  { path: '/account-savelists', component: AccountSavelists },
  { path: '/account-billing', component: AccountBilling },
  //

  //
  { path: '/contact', component: PageContact },
  { path: '/about', component: PageAbout },
  { path: '/signup', component: PageSignUp },
  { path: '/login', component: PageLogin },
  { path: '/subscription', component: PageSubcription }
  //
]

const MyRoutes = () => {
  const authUpdate = useSessionStore((state) => state.authUpdate)
  const dataCmsUpdate = useDataCmsStore((state) => state.dataCmsUpdate)

  const Cms = () => {
    const {
      data,
      loading: loadingCms,
      error: errorCms
    } = useCmsQuery({
      fetchPolicy: 'network-only'
    })
    const dataCms = data?.cms?.data?.attributes ?? {}
    return {
      dataCms,
      loadingCms,
      errorCms
    }
  }

  const { dataCms, loadingCms } = Cms()

  useEffect(() => {
    if (!loadingCms) {
      dataCmsUpdate({
        tituloHeader: dataCms.tituloHeader! || '',
        subTituloHeader: dataCms.subTituloHeader! || '',
        textoBotonHeader: dataCms.textoBotonHeader! || '',
        img1Header: dataCms.img1Header?.data?.attributes?.url! || '',
        // img2Header: dataCms.img1Header!.data?.attributes?.url!,
        img3Header: dataCms.img1Header?.data?.attributes?.url || ''!,
        tituloCiudades: dataCms.tituloCiudades! || '',
        subTituloCiudades: dataCms.subTituloCiudades! || '',
        sloganBeneficios: dataCms.sloganBeneficios! || '',
        tituloBeneficios: dataCms.tituloBeneficios! || '',
        tag1Beneficios: dataCms.tag1Beneficios! || '',
        tag2Beneficios: dataCms.tag2Beneficios! || '',
        tag3Beneficios: dataCms.tag3Beneficios! || '',
        itemTitulo1Beneficios: dataCms.itemTitulo1Beneficios! || '',
        itemTitulo2Beneficios: dataCms.itemTitulo2Beneficios! || '',
        itemTitulo3Beneficios: dataCms.itemTitulo3Beneficios! || '',
        itemDescripcion1Beneficios: dataCms.itemDescripcion1Beneficios! || '',
        itemDescripcion2Beneficios: dataCms.itemDescripcion2Beneficios! || '',
        itemDescripcion3Beneficios: dataCms.itemDescripcion3Beneficios! || '',
        tituloTours: dataCms.tituloTours! || '',
        descripcionTours: dataCms.descripcionTours! || '',
        tituloComoFunciona: dataCms.tituloComoFunciona!,
        descripcionComoFunciona: dataCms.descripcionComoFunciona! || '',
        item1TituloComoFunciona: dataCms.item1TituloComoFunciona! || '',
        item2TituloComoFunciona: dataCms.item2TituloComoFunciona! || '',
        item3TituloComoFunciona: dataCms.item3TituloComoFunciona! || '',
        item1DescripcionComoFunciona:
          dataCms.item1DescripcionComoFunciona! || '',
        item2DescripcionComoFunciona:
          dataCms.item2DescripcionComoFunciona! || '',
        item3DescripcionComoFunciona:
          dataCms.item3DescripcionComoFunciona! || '',
        tituloBoletin: dataCms.tituloBoletin! || '',
        descripcionBoletin: dataCms.descripcionBoletin! || '',
        item1Boletin: dataCms.item1Boletin! || '',
        item2Boletin: dataCms.item2Boletin! || '',
        tituloVideo: dataCms.tituloVideo! || '',
        DescripcionVideo: dataCms.DescripcionVideo! || '',
        tituloTestimonios: dataCms.tituloTestimonios! || '',
        descripcionTestimonios: dataCms.descripcionTestimonios! || '',
        logoClaro: dataCms.logoClaro?.data?.attributes?.url! || '',
        logoOscuro: dataCms.logoOscuro?.data?.attributes?.url || ''!
      })
    }
  }, [loadingCms])

  let WIN_WIDTH = useWindowSize().width
  if (typeof window !== 'undefined') {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth
  }

  useEffect(() => {
    const cookie = findCookie('token')
    authUpdate(cookie)
  }, [])

  return (
    <HashRouter>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component
          return <Route key={path} element={<Component />} path={path} />
        })}
        <Route element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </HashRouter>
  )
}

export default MyRoutes
