'use client'

import { ClockIcon, MapPinIcon } from '@heroicons/react/24/outline'
import React, { useState, useRef, useEffect, FC, Dispatch } from 'react'
import { dataFilterTransladosStore } from '../../store/dataFilterTranslados'
import { AeropuertoEntity } from 'generated/graphql'

import ClearDataButton from 'components/HeroSearchForm/ClearDataButton'

export interface LocationInputComponenteProps {
  placeHolder?: string
  desc?: string
  className?: string
  divHideVerticalLineClass?: string
  autoFocus?: boolean
  dataAeropuertos?: AeropuertoEntity[]
  setinputDesdeAeropuerto: Dispatch<React.SetStateAction<string>>
  loading?: boolean
  inputDesdeAeropuerto: string
}

const LocationInputComponente: FC<LocationInputComponenteProps> = ({
  autoFocus = false,
  placeHolder = 'Location',
  desc = 'Where are you going?',
  className = 'nc-flex-1.5',
  divHideVerticalLineClass = 'left-10 -right-0.5',
  setinputDesdeAeropuerto,
  loading,
  dataAeropuertos,
  inputDesdeAeropuerto
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const updateAirport = dataFilterTransladosStore(
    (state) => state.updateAirport
  )

  const [filterAeroport, setFilterAeroport] = useState<AeropuertoEntity[]>([])
  const [isValueThree, setIsValueThree] = useState(false)
  const [showPopover, setShowPopover] = useState(autoFocus)

  useEffect(() => {
    setFilterAeroport(dataAeropuertos!)
  }, [loading])

  useEffect(() => {
    setShowPopover(autoFocus)
  }, [autoFocus])

  useEffect(() => {
    if (inputDesdeAeropuerto.length >= 3) {
      setIsValueThree(true)

      const newData = dataAeropuertos!.filter((item) => {
        return item.attributes?.aeropuerto
          .toLowerCase()
          .includes(inputDesdeAeropuerto.toLowerCase())
      })
      setFilterAeroport(newData! as AeropuertoEntity[])
      return
    }
    setIsValueThree(false)
  }, [inputDesdeAeropuerto])

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener('click', eventClickOutsideDiv)
    }
    showPopover && document.addEventListener('click', eventClickOutsideDiv)
    return () => {
      document.removeEventListener('click', eventClickOutsideDiv)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover])

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus()
    }
  }, [showPopover])

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return
    }
    // CLICK OUT_SIDE
    setShowPopover(false)
  }

  const handleSelectLocation = (item: string) => {
    if (setinputDesdeAeropuerto) setinputDesdeAeropuerto(item)
    setinputDesdeAeropuerto(item)
    setShowPopover(false)
  }

  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          Búsquedas recientes
        </h3>
        <div className="mt-2">
          {filterAeroport.length === 0 ? (
            <span className="block text-neutral-400 text-center">
              No se encontraron aeropuertos
            </span>
          ) : (
            <>
              {filterAeroport.map((item) => (
                <span
                  onClick={() => {
                    handleSelectLocation(item.attributes?.aeropuerto!)
                    updateAirport(item.attributes?.aeropuerto!)
                  }}
                  key={item.attributes?.aeropuerto}
                  className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                >
                  <span className="block text-neutral-400">
                    <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                  </span>
                  <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                    {item.attributes?.aeropuerto}
                  </span>
                </span>
              ))}
            </>
          )}
        </div>
      </>
    )
  }

  const renderSearchValue = () => {
    return (
      <>
        <span className="block font-medium text-neutral-700 dark:text-neutral-200 text-center">
          Ingresa al menos 3 letras, y guarda los resultado
        </span>
      </>
    )
  }

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? 'nc-hero-field-focused' : ''
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none dark:focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={inputDesdeAeropuerto}
            autoFocus={showPopover}
            onChange={(e) => {
              setinputDesdeAeropuerto(e.currentTarget.value)
            }}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">
              {!!inputDesdeAeropuerto ? placeHolder : desc}
            </span>
          </span>
          {inputDesdeAeropuerto && showPopover && (
            <ClearDataButton
              onClick={() => {
                setinputDesdeAeropuerto('')
              }}
            />
          )}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {isValueThree ? renderRecentSearches() : renderSearchValue()}
        </div>
      )}
    </div>
  )
}

export default LocationInputComponente
