import { useCiudadesFiltersQuery, useCiudadesQuery } from 'generated/graphql'

export const useCities = () => {
  const Cities = () => {
    const {
      data,
      loading: loadingCities,
      error: errorCities
    } = useCiudadesQuery({
      fetchPolicy: 'network-only'
    })
    const dataCities = data?.ciudades?.data ?? []
    return {
      dataCities,
      loadingCities,
      errorCities
    }
  }
  const CitiesFilter = ({ nombre }: { nombre: string }) => {
    const {
      data,
      loading: loadingCities,
      error: errorCities
    } = useCiudadesFiltersQuery({
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          nombre: {
            contains: nombre
          }
        }
      }
    })
    const dataCitiesFilter = data?.ciudades?.data ?? []
    return {
      dataCitiesFilter,
      loadingCities,
      errorCities
    }
  }

  return {
    Cities,
    CitiesFilter
  }
}
