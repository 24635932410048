'use client'

import React, { FC, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'



import { useSearch } from 'service/useSearch'
import { AeropuertoEntity } from 'generated/graphql'

import GuestsInputComponente from 'components/GuestsInputComponente'

import { DataBuyStore } from 'store/dataBuy'
import LocationInputComponente from 'components/LocationInputComponente'
import queryString from 'query-string'
import LocationGoogle from 'components/LocationGoogle'
import StayDatesRangeInputOne from '../StayDatesRangeInputOne'

export interface RentalCarSearchFormProps {}

const RentalCarSearchForm: FC<RentalCarSearchFormProps> = () => {
  const [dropOffLocationType, setDropOffLocationType] = useState<
    'same' | 'different'
  >('different')

  const updateDataBuyPerson = DataBuyStore((state) => state.updateDataBuyPerson)
  const dataBuyPerson = DataBuyStore((state) => state.dataBuyPerson)

  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  // ESTADO PARA EL INPUT DE GOOGLE
  const [value, setValue] = useState(null)

  // ESTADO PARA EL INPUT DESDE AEROPUERTO
  const [inputDesdeAeropuerto, setinputDesdeAeropuerto] = useState(
    queryParams?.dataAeropuerto ? (queryParams.dataAeropuerto as string) : ''
  )
  // ESTADO PARA LOS INPUTS PARA LAS PERSONAS
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    dataBuyPerson.guestAdults
  )
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
    dataBuyPerson.guestChildren
  )
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(
    dataBuyPerson.guestInfants
  )

  // ESTADO PARA LA FECHA
  const [startDate, setStartDate] = useState<Date | null>(new Date())

  const navigate = useNavigate()
  const { dataAeropuertos, loading } = useSearch({
    filters: {
      aeropuerto: {
        contains: inputDesdeAeropuerto
      }
    }
  })
  const handleChange = (value: any) => {
    setValue(value)
  }

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex items-center flex-wrap flex-row border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropOffLocationType === 'different'
              ? 'bg-black text-white shadow-black/10 shadow-lg'
              : 'border border-neutral-300 dark:border-neutral-700'
          }`}
          onClick={(e) => setDropOffLocationType('different')}
        >
          Desde el aeropuerto
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropOffLocationType === 'same'
              ? 'bg-black text-white shadow-black/10 shadow-lg'
              : 'border border-neutral-300 dark:border-neutral-700'
          }`}
          onClick={(e) => setDropOffLocationType('same')}
        >
          Hacia el aeropuerto
        </div>
      </div>
    )
  }

  const isDdropOffdifferent = dropOffLocationType === 'different'

  return (
    <form className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
      {renderRadioBtn()}
      <div className={`relative flex flex-row`}>
        {isDdropOffdifferent && (
          <>
            <LocationInputComponente
              placeHolder="Ingresa un aeropuerto"
              desc="Desde"
              className="flex-1"
              dataAeropuertos={dataAeropuertos as AeropuertoEntity[]}
              loading={loading}
              setinputDesdeAeropuerto={setinputDesdeAeropuerto}
              inputDesdeAeropuerto={inputDesdeAeropuerto}
            />

            <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
            <LocationGoogle
              placeHolder="Ingresa un hotel o dirección adónde quieras ir"
              desc="Hasta"
              className="flex-1"
              divHideVerticalLineClass="-inset-x-0.5"
              value={value as string | null}
              handleChange={handleChange}
            />
          </>
        )}
        {!isDdropOffdifferent && (
          <>
            <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
            <LocationGoogle
              placeHolder="Ingresa un hotel o dirección adónde quieras ir"
              desc="Hasta"
              className="flex-1"
              divHideVerticalLineClass="-inset-x-0.5"
              value={value as string | null}
              handleChange={handleChange}
            />
            <LocationInputComponente
              placeHolder="Ingresa un aeropuerto"
              desc="Desde"
              className="flex-1"
              dataAeropuertos={dataAeropuertos as AeropuertoEntity[]}
              loading={loading}
              setinputDesdeAeropuerto={setinputDesdeAeropuerto}
              inputDesdeAeropuerto={inputDesdeAeropuerto}
            />
          </>
        )}
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInputOne
          className="flex-1 z-[11]"
          startDate={startDate}
          setStartDate={setStartDate}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInputComponente
          setGuestChildrenInputValue={setGuestChildrenInputValue!}
          setGuestAdultsInputValue={setGuestAdultsInputValue!}
          setGuestInfantsInputValue={setGuestInfantsInputValue!}
          guestChildrenInputValue={guestChildrenInputValue!}
          guestAdultsInputValue={guestAdultsInputValue!}
          guestInfantsInputValue={guestInfantsInputValue!}
          hasButtonSubmit
          handleClick={() => {
            updateDataBuyPerson({
              ...dataBuyPerson,
              guestAdults: guestAdultsInputValue,
              guestChildren: guestChildrenInputValue,
              guestInfants: guestInfantsInputValue,
              cantidadDePersonas:
                guestChildrenInputValue! + guestAdultsInputValue!
            })

            navigate(
              `/traslados/?dataAeropuerto=${inputDesdeAeropuerto}&dataCantidad=${
                guestChildrenInputValue! + guestAdultsInputValue!
              }`
            )
          }}
        />

        {/* <GuestsInput className="flex-1" isTranslados startDate={startDate} /> */}
      </div>
    </form>
  )
}

export default RentalCarSearchForm
