import {
  CompraInput,
  useComprasQuery,
  useCreateCompraMutation
} from 'generated/graphql'

export const useCompra = () => {
  const [createCompraMutation] = useCreateCompraMutation()

  const Compras = () => {
    const {
      data,
      loading: loadingCompras,
      error: errorCompras
    } = useComprasQuery({
      fetchPolicy: 'network-only'
    })
    const dataCompras = data?.compras?.data ?? []
    return {
      dataCompras,
      loadingCompras,
      errorCompras
    }
  }

  return {
    Compras,
    Compra: async (input: CompraInput) => {
      const res = await createCompraMutation({
        variables: { data: input }
      })

      const data = res.data

      return {
        data
      }
    }
  }
}
