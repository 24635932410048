import React, { FC } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import HeroSearchForm from 'components/HeroSearchForm/HeroSearchForm'
import { useDataCmsStore } from 'store/dataCms'
import { RenderLoading } from 'shared/Show'

export interface SectionHeroProps {
  className?: string
}

const SectionHero: FC<SectionHeroProps> = ({ className = '' }) => {
  const dataCms = useDataCmsStore((state) => state.dataCms)
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          {dataCms.tituloHeader.length === 0 ? (
            <RenderLoading />
          ) : (
            <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
              {dataCms.tituloHeader}{' '}
            </h2>
          )}
          {dataCms.subTituloHeader.length === 0 ? (
            <RenderLoading />
          ) : (
            <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
              {dataCms.subTituloHeader}
            </span>
          )}

          {dataCms.textoBotonHeader.length === 0 ? (
            <RenderLoading />
          ) : (
            <ButtonPrimary>{dataCms.textoBotonHeader}</ButtonPrimary>
          )}
        </div>
        <div className="flex-grow">
          {dataCms.img1Header.length === 0 ? (
            <RenderLoading />
          ) : (
            <img className="w-full" src={dataCms.img1Header} alt="hero" />
          )}
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div>
    </div>
  )
}

export default SectionHero
