import ModalReserveMobile from 'components/ModalReserveMobile'

import ButtonPrimary from 'shared/Button/ButtonPrimary'

interface Props {
  handleReserva: () => Promise<void>
  precio: number
  duracion: string
}

const MobileFooterStickyCustom = ({
  handleReserva,
  precio,
  duracion
}: Props) => {
  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
            ${precio}
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              /{duracion}
            </span>
          </span>
        </div>
        <ModalReserveMobile
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              onClick={handleReserva}
            >
              Reserve
            </ButtonPrimary>
          )}
        />
      </div>
    </div>
  )
}

export default MobileFooterStickyCustom
