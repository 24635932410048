import React, { FC } from "react";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import { formatHour } from "utils/formatHour";
import { Traslado } from "generated/graphql";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "store/session";
import { DataBuyStore } from "store/dataBuy";
import { useUser } from "service/useUser";
import { Toast } from "utils/toast";

export interface CarAirpotProps {
  className?: string;
  data?: Traslado;
  size?: "default" | "small";
  id?: string;
  showBuy?: boolean;
}

const CardAirpot: FC<CarAirpotProps> = ({
  size = "default",
  className = "",
  data,
  id,
  showBuy = true,
}) => {
  const router = useNavigate();

  const auth = useSessionStore((state) => state.auth);
  const user = useSessionStore((state) => state.user);
  const currenly = DataBuyStore((state) => state.currenly);
  const updateDataBuyPerson = DataBuyStore(
    (state) => state.updateDataBuyPerson
  );
  const dataBuyPerson = DataBuyStore((state) => state.dataBuyPerson);

  const { User } = useUser();
  const { dataUser } = User(user.id);

  // const {  saleOff } = data

  const img =
    data?.vehiculo?.data?.attributes?.imagenReferencia?.data?.attributes?.url;

  const handleReserva = async () => {
    if (
      +data?.vehiculo?.data?.attributes?.CapacidadMaximaPasajeros! <
      dataBuyPerson.guestAdults! + dataBuyPerson.guestChildren!
    ) {
      Toast({
        type: "error",
        message: "No debes superar la capacidad maxima de pasajeros",
      });
      return;
    }
    if (!auth) {
      router("../login");
      return;
    }
    const res = await fetch("https://api.travia.com.co/api/procesarPago", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nombre: `${data?.TipodeServicio} - ${data?.vehiculo?.data?.attributes?.tipodeVehiculo}`,
        description: `${data?.zonadeArribos} - ${data?.destino}`,
        idProducto: id,
        area_code_payer: "57",
        currency: currenly,
        cantidad: 1,
        numberIDPayer: user.id,
        category_id: "traslado",
        typeidPayer: "CC",
        precio: data?.precio || 100,
        urlImagen: img,
        // description: `${data?.zonadeArribos} - ${data?.destino}`,
        nombreCliente: dataUser.attributes?.nameComplete,
        apellidoCliente: dataUser.attributes?.username,
        emailCliente: dataUser.attributes?.email,
        telefonoCliente: 3112571830,
        codigoPostal: "730001",
        direccion: "calle 20",
        numeroDireccion: 1258,
        numeroIdentificacion: dataUser.attributes?.cedula,
        coupon_amount: 100,
      }),
    });
    const datatraslado = await res.json();
    if (datatraslado.init) {
      updateDataBuyPerson({
        ...dataBuyPerson,
        fechaDeCompra: new Date(),
        total: data?.precio,
        traslados: id!,
        plan: "traslado",
      });
      router(`/checkout/${datatraslado.id}`);
    }
  };
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden">
        <div className="aspect-w-16 aspect-h-9 ">
          <NcImage
            containerClassName="flex items-center justify-center"
            className="w-full"
            src={
              data?.vehiculo?.data?.attributes?.imagenReferencia?.data
                ? data?.vehiculo?.data.attributes?.imagenReferencia?.data
                    ?.attributes?.url
                : "https://res.cloudinary.com/dvydysqs4/image/upload/v1687465937/Hilux_SRX_destacada_2_8_AT_e2116c1901.jpg"
            }
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {data?.isAds && <Badge name="ADS" color="green" />}
            <h2
              className={`  capitalize ${
                size === "default"
                  ? "text-xl font-semibold"
                  : "text-base font-medium"
              }`}
            >
              <div className="flex">
                <span className="line-clamp-1">{data?.TipodeServicio}</span> -
                <span className="line-clamp-1">
                  {data?.vehiculo?.data?.attributes?.tipodeVehiculo}
                </span>
              </div>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <span className="">
              {data?.vehiculo?.data?.attributes?.CapacidadMaximaPasajeros}{" "}
              Personas
            </span>
            <span>-</span>
            <span className="">
              {formatHour(
                data?.duracionMaximaHoras!,
                data?.duracionMaximaMinutos!
              )}{" "}
            </span>
          </div>
        </div>
        <div className="w-14  border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            ${data?.precio}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /day
              </span>
            )}
          </span>
          {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
        </div>
        {showBuy && (
          <div className="flex justify-center">
            <ButtonPrimary onClick={handleReserva}>Comprar</ButtonPrimary>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform ${className}`}
      data-nc-id="CarCard"
    >
      <div className="flex flex-col">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default CardAirpot;
