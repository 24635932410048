import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import SectionHeroArchivePage from 'components/SectionHeroArchivePage/SectionHeroArchivePage'

import React, { FC, useState } from 'react'
import SectionGridFilterCard from './SectionGridFilterCard'
import { Helmet } from 'react-helmet'
import heroRightImage from 'images/hero-right-car.png'
import { useLocation } from 'react-router-dom'
import { useTranslados } from 'service/useTranslados'
import { InputMaybe, TrasladoEntity } from 'generated/graphql'
import Heading from 'components/Heading/Heading'
import queryString from 'query-string'
import { DataBuyStore } from 'store/dataBuy'
import { RenderLoading, Show } from 'shared/Show'
import PaginationPage from 'shared/PaginationPage'

export interface TrasladosPageProps {
  className?: string
}

const TrasladosPage: FC<TrasladosPageProps> = ({ className = '' }) => {
  const [statePaginator, setStatePaginator] = useState({
    pagina: 1,
    numeroPagina: 10
  })
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const { Translados } = useTranslados()
  const dataBuyPerson = DataBuyStore((state) => state.dataBuyPerson)

  const { dataTranslados, dataPagination, loading } = Translados({
    filters: {
      aeropuerto: {
        aeropuerto: {
          eq: queryParams?.dataAeropuerto! as InputMaybe<string> | undefined
        }
      },
      and: [
        {
          vehiculo: {
            CapacidadMaximaPasajeros: {
              gte: dataBuyPerson.guestChildren! + dataBuyPerson.guestAdults!
            }
          }
        }
      ]
    }
    // pagination: {
    //   pageSize: statePaginator.numeroPagina,
    //   page: statePaginator.pagina
    // }
  })

  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidden ${className}`}
      data-nc-id="ListingCarPage"
    >
      <Helmet>
        <title>Travia | Traslados</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          rightImage={heroRightImage}
          city={(queryParams?.dataAeropuerto! as string) || ''}
          currentPage="Traslados"
          currentTab="Traslados"
          title="Traslados, Travia"
          listingType={
            <>
              <i className="text-2xl las la-car"></i>
              <span className="ml-2.5">{dataTranslados.length} Traslados</span>
            </>
          }
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
        />

        {/* SECTION */}
        {queryParams.dataCantidad && queryParams.dataAeropuerto ? (
          <Show condition={!loading} isDefault={<RenderLoading />}>
            {dataTranslados.length === 0 ? (
              <Heading desc="">No hay Tours disponibles</Heading>
            ) : (
              <>
                <SectionGridFilterCard
                  className="pb-10 lg:pb-12"
                  data={dataTranslados! as TrasladoEntity[]}
                />
                <div className="flex  justify-center items-center mb-8">
                  {/* <PaginationPage
                    nTotal={dataPagination}
                    state={statePaginator}
                    setState={setStatePaginator}
                  /> */}
                </div>
              </>
            )}
          </Show>
        ) : (
          <Heading desc="">No hay Traslados disponibles</Heading>
        )}
      </div>
    </div>
  )
}

export default TrasladosPage
