import React from 'react'
import { Link } from 'react-router-dom'
import logoImg from 'images/logo.png'
import logoLightImg from 'images/logo-light.png'
import LogoSvgLight from '../../images/logo-light-travia.png'
import LogoSvg from '../../images/logo-dark.png'
import { useDataCmsStore } from 'store/dataCms'

export interface LogoProps {
  img?: string
  imgLight?: string
  className?: string
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = 'w-24'
}) => {
  const dataCms = useDataCmsStore((state) => state.dataCms)
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {/* <LogoSvgLight />
      <LogoSvg /> */}

      <img
        className="block w-[80px]  dark:hidden"
        src={dataCms.logoClaro.length === 0 ? LogoSvgLight : dataCms.logoClaro}
        alt="Logo"
      />
      <img
        className="hidden w-[80px] dark:block"
        src={dataCms.logoOscuro.length === 0 ? LogoSvg : dataCms.logoOscuro}
        alt="Logo"
      />

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {/* {img ? (
        <img
          className={`block max-h-12 ${imgLight ? 'dark:hidden' : ''}`}
          src={img}
          alt="Logo"
        />
      ) : (
        'Logo Here'
      )}
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )} */}
    </Link>
  )
}

export default Logo
