import { MapPinIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { AeropuertoEntity, TourEntity } from 'generated/graphql'
import React, {
  useState,
  useEffect,
  useRef,
  FC,
  Dispatch,
  useMemo
} from 'react'
import { useCities } from 'service/useCities'

interface Props {
  onClick?: () => void
  onChange?: (value: string) => void
  className?: string
  defaultValue?: string
  headingText?: string
  setValueSearch?: Dispatch<React.SetStateAction<string>>
  dataAeropuertos?: AeropuertoEntity[]
  dataTours?: TourEntity[]
  variante?: 'tours' | 'traslados'
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  className = '',
  defaultValue = 'United States',
  headingText = '¿A donde?',
  setValueSearch,
  dataAeropuertos,
  variante,
  dataTours
}) => {
  const [value, setValue] = useState('')
  const containerRef = useRef(null)
  const inputRef = useRef(null)

  const { Cities } = useCities()
  const { dataCities, loadingCities } = Cities()
  const cities = useMemo(
    () => dataCities.map((item) => item.attributes?.nombre).slice(0, 6),
    [loadingCities]
  )

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleSelectLocation = (item: string) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValue(item)
      onChange && onChange(item)
    }, 0)
  }

  const renderSearchValues = ({
    heading,
    items
  }: {
    heading: string
    items: string[]
  }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || 'Destinations'}
        </p>
        <div className="mt-3">
          {variante === 'traslados' && (
            <>
              {dataAeropuertos?.map((item) => {
                return (
                  <div
                    className="py-2 mb-1 flex items-center space-x-3 text-sm"
                    onClick={() =>
                      handleSelectLocation(item.attributes?.aeropuerto!)
                    }
                    key={item.id}
                  >
                    <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                    <span className="">{item.attributes?.aeropuerto!}</span>
                  </div>
                )
              })}
            </>
          )}
          {variante === 'tours' && (
            <>
              {dataTours?.map((item) => {
                return (
                  <div
                    className="py-2 mb-1 flex items-center space-x-3 text-sm"
                    onClick={() =>
                      handleSelectLocation(item.attributes?.ciudad!)
                    }
                    key={item.id}
                  >
                    <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                    <span className="">{item.attributes?.ciudad!}</span>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </>
    )
  }

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={'Buscar destinos'}
            value={value}
            onChange={(e) => {
              setValue(e.currentTarget.value)
              if (setValueSearch) {
                setValueSearch(e.currentTarget.value)
              }
            }}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {value
            ? renderSearchValues({
                heading: 'Destinos populares',
                items: cities! as string[]
              })
            : renderSearchValues({
                heading: 'Destinos populares',
                items: cities! as string[]
              })}
        </div>
      </div>
    </div>
  )
}

export default LocationInput
