import Heading from 'components/Heading/Heading'
import React, { FC } from 'react'
import NcImage from 'shared/NcImage/NcImage'
import HIW1img from 'images/HIW1.png'
import HIW2img from 'images/HIW2.png'
import HIW3img from 'images/HIW3.png'
import VectorImg from 'images/VectorHIW.svg'
import { useDataCmsStore } from 'store/dataCms'

export interface SectionHowItWorkProps {
  className?: string
  data?: {
    id: number
    title: string
    desc: string
    img: string
    imgDark?: string
  }[]
}

// const DEMO_DATA: SectionHowItWorkProps['data'] = [
//   {
//     id: 1,
//     img: HIW1img,
//     title: 'Reserva y relájate',
//     desc: 'Deja que cada viaje sea una experiencia inspiradora y cada destino un espacio de paz y disfrute.'
//   },
//   {
//     id: 2,
//     img: HIW2img,
//     title: 'Lista inteligente',
//     desc: 'Simplifica tu planificación de viaje con nuestra lista inteligente, que te guiará paso a paso para asegurarte de no olvidar ningún detalle importante.'
//   },
//   {
//     id: 3,
//     img: HIW3img,
//     title: 'Ahorra más',
//     desc: 'Descubre nuestras ofertas y promociones exclusivas para ahorrar en tus viajes y disfrutar al máximo de cada experiencia.'
//   }
// ]

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = '',
  data
}) => {
  const dataCms = useDataCmsStore((state) => state.dataCms)

  const DEMO_DATA: SectionHowItWorkProps['data'] = [
    {
      id: 1,
      img: HIW1img,
      title: dataCms.item1TituloComoFunciona,
      desc: dataCms.item1DescripcionComoFunciona
    },
    {
      id: 2,
      img: HIW2img,
      title: dataCms.item2TituloComoFunciona,
      desc: dataCms.item2DescripcionComoFunciona
    },
    {
      id: 3,
      img: HIW3img,
      title: dataCms.item3TituloComoFunciona,
      desc: dataCms.item3DescripcionComoFunciona
    }
  ]

  data = DEMO_DATA
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <Heading isCenter desc={dataCms.descripcionComoFunciona}>
        {dataCms.tituloComoFunciona}
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            {item.imgDark ? (
              <>
                <NcImage
                  containerClassName="dark:hidden block mb-8 max-w-[200px] mx-auto"
                  src={item.img}
                />
                <NcImage
                  containerClassName="hidden dark:block mb-8 max-w-[200px] mx-auto"
                  src={item.imgDark}
                />
              </>
            ) : (
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={item.img}
              />
            )}
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SectionHowItWork
