import { ApolloProvider } from '@apollo/client'
import client from 'apollo'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import MyRouter from 'routers/index'
import InstallPWA from 'components/Pwa'
import Offiline from 'components/Offiline'
import { useLoadStore } from 'store/load'
import { RenderLoading } from 'shared/Show'

function App() {
  return (
    <ApolloProvider client={client}>
      <Offiline>
        <InstallPWA />
        <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
          <MyRouter />
        </div>
      </Offiline>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ApolloProvider>
  )
}

export default App
