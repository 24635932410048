import React, { FC } from 'react'

import { v4 } from 'uuid'

import Heading2 from 'components/Heading/Heading2'
import { Tour, TourEntity } from 'generated/graphql'
import ToursCard from 'components/ToursCard'

export interface SectionGridFilterCardProps {
  className?: string
  data?: TourEntity[]
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = '',
  data
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading={`Tours encontrados ${data?.length}`}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3"></span>
        }
      />

      <div className="mb-8 lg:mb-11">{/* <TabFilters /> */}</div>
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {data &&
          data?.map((tour) => (
            <ToursCard key={v4()} data={tour.attributes as Tour} id={tour.id} />
          ))}
      </div>
      <div className="flex mt-16 justify-center items-center">
      </div>
    </div>
  )
}

export default SectionGridFilterCard
