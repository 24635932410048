// import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import React, { FC, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'

import SectionHeroArchivePage from 'components/SectionHeroArchivePage/SectionHeroArchivePage'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'

import SectionGridFilterCard from './SectionGridFilterCard'

import { useTours } from 'service/useTours'

import { RenderLoading, Show } from 'shared/Show'
import Heading from 'shared/Heading/Heading'

import { InputMaybe, TourEntity } from 'generated/graphql'
import queryString from 'query-string'
import { cutString } from 'utils/cutString'
import PaginationPage from 'shared/PaginationPage'

export interface TourPageProps {
  className?: string
}

const TourPage: FC<TourPageProps> = ({ className = '' }) => {
  const { Tours } = useTours()
  const [statePaginator, setStatePaginator] = useState({
    pagina: 1,
    numeroPagina: 1
  })

  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const { dataTours, loadingTours, dataPagination } = Tours({
    filters: {
      ciudad: {
        eq: queryParams?.dataCiudad! as InputMaybe<string> | undefined
      }
    }
  })
  return (
    <div
      className={`nc-ListingExperiencesPage relative overflow-hidden ${className}`}
      data-nc-id="ListingExperiencesPage"
    >
      <Helmet>
        <title>Travia | Tours</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Tours"
          currentTab="Tours"
          title="Tours, Travia"
          city={cutString(15, queryParams?.dataCiudad! as string) || ''}
          listingType={
            <>
              <i className="text-2xl las la-umbrella-beach"></i>
              <span className="ml-2.5">{dataTours.length} Tours</span>
            </>
          }
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
        />

        {/* SECTION */}
        {!queryParams?.dataCiudad ? (
          <Heading desc="">No hay Tours disponibles</Heading>
        ) : (
          <Show condition={!loadingTours} isDefault={<RenderLoading />}>
            {dataTours.length === 0 ? (
              <Heading desc="">No hay Tours disponibles</Heading>
            ) : (
              <>
                <SectionGridFilterCard
                  className="pb-10 lg:pb-12"
                  data={dataTours as TourEntity[]}
                />
                {/* <div className="flex  justify-center items-center mb-8">
                  <PaginationPage
                    nTotal={dataPagination}
                    state={statePaginator}
                    setState={setStatePaginator}
                  />
                </div> */}
              </>
            )}
          </Show>
        )}
        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-28" />
      </div>
    </div>
  )
}

export default TourPage
