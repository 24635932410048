import { AeropuertoFiltersInput, useAeropuertosQuery } from 'generated/graphql'

export const useSearch = ({ filters }: { filters: AeropuertoFiltersInput }) => {
  const { data, loading, error } = useAeropuertosQuery({
    fetchPolicy: 'network-only',
    variables: {
      filters
    }
  })
  const dataAeropuertos = data?.aeropuertos?.data ?? []
  return {
    dataAeropuertos,
    loading,
    error
  }
}
